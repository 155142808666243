import * as React from 'react'

export const arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="112.2"
    height="90.13"
    viewBox="0 0 112.2 90.13"
  >
    <path
      d="M108.16,3.43C105.5.94,101.68,1,99.45,3.57c-3.77,3.87-7.37,7.41-11.21,11s-8.1,6.81-12.09,9.92A108,108,0,0,1,63.1,33.09a95.25,95.25,0,0,1-14,7.2c-4.63,1.94-9.58,3.69-14.78,5.53-4.77,1.43-9.8,2.93-15.15,4.26,1.66-8.16,4.12-7.26,8.24-14.52L35.67,21a6.31,6.31,0,1,0-10.93-6.31c-3.77,6.61-3.52,6.53-7.29,13.14S13.68,34.48,10.16,41,6.39,47.62,2.87,54.15A9.06,9.06,0,0,0,7,66.89c7.36,3.54,7.36,3.54,14.65,6.84C29,77.27,28.89,77,36.25,80.57s7.29,3.29,14.65,6.83a7.14,7.14,0,0,0,3.47.61A6.37,6.37,0,0,0,56.23,76c-7.61-3.47-7.69-3.72-15.3-7.19S32,67.32,25.63,61.57q8.4-2.12,15.83-4.75a152.8,152.8,0,0,0,15.13-6.17A144.83,144.83,0,0,0,71,43a158.73,158.73,0,0,0,13.64-9.29A111.48,111.48,0,0,0,97.35,23.1c4.1-3.7,7.87-7.56,11.64-11.43C110.63,9.81,110.5,5.74,108.16,3.43Z"
      fill="#ffc605"
    />
  </svg>
)
