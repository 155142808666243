export const PALETTE = {
  BLACK: '#000000',
  LIGHT_GREY: '#F5F5F5',
  DARK_GREY: '#1A2C38',
  WHITE: '#ffffff',
  BLUE: '#4BB6C1',
  RED: '#E13F3F',
  ORANGE: '#EF6C52',
  PURPLE: '#A368A5',
  BEIGE: '#F3F0E0',
  YELLOW: '#EFB81B',
}

export const COLOR = {
  BASE_ACCENT: PALETTE.PURPLE,
  BASE_BACKGROUND: PALETTE.WHITE,
  BASE_FONT: PALETTE.BLACK,
  LINK_FONT: PALETTE.BLACK,
  ...PALETTE,
}
