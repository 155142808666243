import { createUseStyles } from 'react-jss'

import { MQ, COLOR } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: [100, 0, 72],
    maxWidth: 1920,
    margin: 'auto',
    position: 'relative',
    [MQ.TABLET]: {
      padding: [75, '8%', 85],
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    [MQ.DESKTOP]: {
      padding: [145, '6%', 20, '6%'],
      justifyContent: 'flex-start',
    },
    [MQ.DESKTOP_LARGE]: {
      padding: [175, '6%', 35, '6%'],
    },
  },
  imageWrapper: {
    width: '80%',
    position: 'relative',
    [MQ.TABLET]: {
      width: '46%',
    },
    [MQ.DESKTOP]: {
      width: '34%',
    },
  },
  textBlock: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    [MQ.TABLET]: {
      width: '43%',
    },
    [MQ.DESKTOP]: {
      width: '55%',
      marginLeft: '5%',
    },
  },
  title: {
    margin: [84, 0, 0, 0],
    [MQ.TABLET]: {
      margin: 0,
    },
  },
  subTitle: {
    margin: [20, 0, 0, 0],
    [MQ.DESKTOP]: {
      margin: [30, 0, 0],
    },
  },
  body: {
    margin: [40, 0, 0],
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [MQ.TABLET]: {
      width: '84%',
      margin: 'auto',
      paddingBottom: 40,
    },
    [MQ.DESKTOP]: {
      display: 'flex',
      margin: [32, 0, 0],
      padding: 0,
    },
    [MQ.DESKTOP_LARGE]: {
      margin: [40, 0, 0],
    },
  },
  sunrise: {
    position: 'absolute',
    width: 125,
    height: 90,
    top: 40,
    right: 7,
    transform: 'rotate(-33deg)',

    [MQ.TABLET]: {
      display: 'none',
    },
    [MQ.TABLET_LARGE]: {
      display: 'flex',
      width: 150,
      height: 125,
      top: -60,
    },
    [MQ.DESKTOP]: {
      transform: 'rotate(0deg)',
      width: 194,
      height: 165,
      top: -75,
      right: 150,
    },
  },
  sunriseTablet: {
    [MQ.TABLET]: {
      transform: 'rotate(-33deg)',
      width: 150,
      height: 100,
      right: -45,
      top: -65,
      position: 'absolute',
    },
    [MQ.DESKTOP]: {
      display: 'none',
    },
  },
  noodle: {
    position: 'absolute',
    width: 100,
    height: 75,
    bottom: -40,
    left: '15%',

    [MQ.TABLET]: {
      width: 100,
      height: 100,
      bottom: -70,
    },

    [MQ.DESKTOP]: {
      width: 160,
      height: 172,
      bottom: -120,
    },
  },
  arrow: {
    [MQ.DESKTOP]: {
      position: 'absolute',
      width: 75,
      height: 45,
      top: 30,
      left: 365,
    },
  },
  button: {
    alignSelf: 'center',
    margin: [50, 0, 0],
  },
  tabletBody: {
    display: 'none',
    [MQ.TABLET]: {
      display: 'block',
    },
    [MQ.DESKTOP]: {
      display: 'none',
    },
  },
  otherBody: {
    [MQ.TABLET]: {
      display: 'none',
    },
    [MQ.DESKTOP]: {
      display: 'none',
    },
  },
  arrowButton: {
    position: 'relative',
    alignSelf: 'center',
    width: '100%',
    marginTop: 30,
    [MQ.DESKTOP]: {
      alignSelf: 'flex-start',
    },
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: 342,
  },
  storeLinks: {
    width: '100%',
    margin: [30, 0, 0],
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 261,
    [MQ.TABLET_LARGE]: {
      margin: [30, 0, 0],
    },
  },
  store: {
    width: '43%',
    height: 45,
    padding: [2.5, 5],
  },
  noLinkStore: {
    backgroundColor: COLOR.ORANGE,
    borderRadius: 10,
  },
})
