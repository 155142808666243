export const DEEP_LINKS = {
  FALL_BACK: 'https://noveleffect.page.link/annies',
  RFB: 'https://noveleffect.page.link/annies-rfb', // Referer Facebook
  RFBP: 'https://noveleffect.page.link/annies-rfbp', // Referer Facebook Paid
  RIG: 'https://noveleffect.page.link/annies-rig', // Referer Instagram
  RIGP: 'https://noveleffect.page.link/annies-rigp', // Referer Instagram Paid
}

export const LINKS = {
  ANDROID:
    'https://play.google.com/store/apps/details?id=com.noveleffect.book&hl=en_US&gl=US',
  ANNIES_BASKET:
    'https://shop.basketful.co/bundle?bundle=2b0e6dd6-2953-4ed2-a7c0-5a3057cf4993&utm_source=noveleffect&utm_medium=landingpage&utm_campaign=f21easter',
  ANNIES_INSTAGRAM: 'https://www.instagram.com/annieshomegrown',
  ANNIES: 'https://annies.com',
  APPLE:
    'https://apps.apple.com/us/app/novel-effect-read-aloud-books/id1057374139',
  CONTACT: 'https://noveleffect.com/contact/',
  FACEBOOK: 'https://www.facebook.com/noveleffect/',
  INSTAGRAM: 'https://www.instagram.com/novel_effect/',
  NOVEL_EFFECT: 'https://noveleffect.com/',
  SUBSCRIBE: 'https://noveleffect.com/subscribe/',
}
