import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  text: {
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    top: -10,
    width: 1,
  },
})
