import * as React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useMedia } from 'react-use'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text, Svg } from '@atoms'
import { DownloadButtons } from '@molecules'
import { COLOR } from '@constants'

export const HowItWorks = ({ image }) => {
  const classes = useStyles()
  const imgSrc = getImage(image)

  const isMobile = useMedia('(max-width: 599px)')
  const isDesktop = useMedia('(min-width: 1279px)')
  const isMobileOrDesktop = isMobile || isDesktop
  const isTablet = !isMobile && !isDesktop

  const body = ({ styles }) => (
    <div className={cn(classes.body, styles)}>
      <Text size="body1" tag="p">
        Annie’s&#8482; and Novel Effect are teaming up to make your mealtime
        with your little ones magical. Just grab some Annie’s&#8482; Mac &amp;
        Cheese, have your kiddos do the special craft on the back of the box
        while the noodles are cooking, and open the Novel Effect app to see your
        back-of-the-box character come to life in brand new Annie’s&#8482;
        stories! These stories were written, illustrated, and scored just for
        the new Annie’s&#8482; Build To Play activities.
      </Text>
      <div className={classes.arrowButton}>
        <div className={classes.arrow}>
          <Svg name="arrow" pathFill={COLOR.PURPLE} />
        </div>
        <DownloadButtons
          text="DOWNLOAD THE FREE APP"
          page="section1"
          buttonColor={COLOR.ORANGE}
          textColor={COLOR.BLACK}
        />
      </div>
    </div>
  )
  const tabletBody = isTablet && body(classes.tabletBody)
  const otherBody = isMobileOrDesktop && body(classes.otherBody)

  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.imageWrapper}>
          <GatsbyImage image={imgSrc} alt="image" />
          <div className={classes.noodle}>
            <Svg name="noodle" />
          </div>
          {isTablet && (
            <div className={classes.sunriseTablet}>
              <Svg name="sunrise" />
            </div>
          )}
        </div>
        <div className={classes.textBlock}>
          <div className={classes.title}>
            <Text size="header1" tag="h2">
              A good story and some mac & cheese
            </Text>
          </div>
          <div className={classes.subTitle}>
            <Text size="header2" tag="h3">
              What's not to love?
            </Text>
          </div>
          {otherBody}
        </div>
        {isMobileOrDesktop && (
          <div className={classes.sunrise}>
            <Svg name="sunrise" />
          </div>
        )}
      </div>
      {tabletBody}
    </div>
  )
}

HowItWorks.propTypes = {
  image: PropTypes.object,
}

HowItWorks.defaultProps = {
  image: null,
}
