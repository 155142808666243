import * as React from 'react'

export const halfCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="190.65"
    height="167.2"
    viewBox="0 0 190.65 167.2"
  >
    <path d="M168.74,1.49A102.69,102.69,0,0,1,2.43,122Z" fill="#efb81b" />
  </svg>
)
