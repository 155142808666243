import { DEEP_LINKS } from '@constants'

export function getOperatingSystemLink() {
  if (typeof navigator !== `undefined`) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    ) {
      return getDeepLink()
    }

    return null
  }
}

export const getDeepLink = () => {
  if (typeof window === `undefined`) return DEEP_LINKS.FALL_BACK

  const isMobileDevice = () => {
    if (typeof navigator !== `undefined`) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      if (
        /android/i.test(userAgent) ||
        (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
      ) {
        return true
      }
      return false
    }
    return false
  }

  if (!isMobileDevice) return null

  const urlParams = new URLSearchParams(window.location.search)
  const utmSource = urlParams.get('utm_source')
  const utmMedium = urlParams.get('utm_medium')

  if (utmSource === 'facebook') {
    if (utmMedium === 'paidsocial') return DEEP_LINKS.RFBP
    if (utmMedium === 'social') return DEEP_LINKS.RFB
  }

  if (utmSource === 'instagram') {
    if (utmMedium === 'paidsocial') return DEEP_LINKS.RIGP
    if (utmMedium === 'social') return DEEP_LINKS.RIG
  }

  return DEEP_LINKS.FALL_BACK
}
