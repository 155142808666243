import { FONTS } from '@constants'

export const TYPOGRAPHY = {
  EXAMPLE: {
    fontFamily: FONTS.ARIAL,
    fontStyle: 'normal',
    fontWeight: 900,
  },
  LOTA_REGULAR: {
    fontFamily: FONTS.LOTA,
    fontWeight: 400,
  },
  LOTA_SEMI_BOLD: {
    fontFamily: FONTS.LOTA,
    fontWeight: 500,
  },
  LOTA_BOLD: {
    fontFamily: FONTS.LOTA,
    fontWeight: 600,
  },
}
