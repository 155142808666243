import * as React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { isNilOrEmpty } from 'ramdasauce'

import { useStyles } from './styles'
import { Text } from '@atoms'
import { LINKS } from '@constants'

export const Button = ({
  link,
  text,
  color,
  styles,
  textStyle,
  className,
  whiteBkg,
}) => {
  const classes = useStyles({ color, whiteBkg })

  const buttonClasses = cn({
    [classes.button]: true,
    [className]: !isNilOrEmpty(className),
    [classes.wideButton]: styles === 'wide',
    [classes.noLink]: isNilOrEmpty(link),
  })

  return (
    <a className={buttonClasses} href={link} target="_blank" rel="noreferrer">
      <Text size={textStyle}>{text}</Text>
    </a>
  )
}

Button.propTypes = {
  text: PropTypes.any,
  link: PropTypes.string,
  color: PropTypes.string,
  styles: PropTypes.string,
  textStyle: PropTypes.string,
  className: PropTypes.string,
}

Button.defaultProps = {
  text: 'Button',
  color: 'black',
  link: LINKS.NOVEL_EFFECT,
  styles: 'normal',
  textStyle: 'button1',
  className: null,
}
