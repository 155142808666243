import { createUseStyles } from 'react-jss'

import { MQ, COLOR, Z_INDEX } from '@constants'

import mobileWave180 from '@images/heroWave.svg'
import mobileWavePurple from '@images/heroWavePurple.svg'

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    position: 'relative',
    color: COLOR.WHITE,
    [MQ.TABLET]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 225,
    },
    [MQ.DESKTOP]: {
      marginBottom: 0,
    },
  },
  firstColumn: {
    width: '100%',
    marginTop: 370,
    [MQ.TABLET]: {
      marginTop: 0,
      width: '57%',
    },
    [MQ.TABLET_LARGE]: {
      width: '48%',
    },
  },
  textBlock: {
    backgroundColor: 'rgba(163, 104, 165, 0.86)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    padding: '0 10%',
    zIndex: Z_INDEX.CONTENT,
    [MQ.TABLET]: {
      alignItems: 'flex-start',
      padding: [0, '7%', 0, '16%'],
      backgroundColor: COLOR.PURPLE,
    },
    [MQ.DESKTOP]: {
      paddingBottom: 40,
    },
    [MQ.DESKTOP_XL]: {
      padding: [250, '18.2%', 250],
    },
    '&:before': {
      background: `url("${mobileWavePurple}") center`,
      content: '""',
      position: 'absolute',
      height: 17,
      left: 0,
      top: -17,
      width: '100%',
      zIndex: Z_INDEX.OVERLAY,
      transform: 'rotate(180deg)',
      [MQ.TABLET]: {
        display: 'none',
      },
    },
  },
  title: {
    maxWidth: 291,
    margin: [20, 0, 0],
    textAlign: 'center',
    [MQ.TABLET]: {
      textAlign: 'left',
      margin: [150, 0, 48],
    },
    [MQ.DESKTOP]: {
      maxWidth: 464,
      margin: [150, 0, 36],
    },
    [MQ.DESKTOP_LARGE]: {
      margin: [185, 0, 36],
    },
  },
  mobileTitle: {
    [MQ.TABLET]: {
      display: 'none',
    },
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 300,
    [MQ.TABLET]: {
      alignItems: 'flex-start',
      maxWidth: 'unset',
      width: 'auto',
    },
  },
  storeLinks: {
    width: '100%',
    margin: [30, 0, 40],
    display: 'flex',
    justifyContent: 'center',
    [MQ.TABLET]: {
      marginBottom: 124,
      justifyContent: 'space-between',
    },
    [MQ.TABLET_LARGE]: {
      margin: [30, 0, 50],
    },
  },
  store: {
    width: '37%',
    height: 45,
    padding: [5, 10],
    margin: [0, 10],
    [MQ.TABLET]: {
      margin: [0, 20, 0, 0],
      width: '43%',
    },
  },
  noLinkStore: {
    backgroundColor: COLOR.BLUE,
    borderRadius: 10,
  },
  logoWrapper: {
    width: '100%',
    backgroundColor: COLOR.BEIGE,
    padding: [50, 0],
    position: 'relative',
    zIndex: Z_INDEX.CONTENT,
    display: 'none',
    [MQ.TABLET]: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      zIndex: Z_INDEX.OVERLAY,
    },
    [MQ.DESKTOP]: {
      position: 'relative',
    },
    '&:before': {
      background: `url("${mobileWave180}") center`,
      content: '""',
      position: 'absolute',
      height: 17,
      left: 0,
      top: -15,
      width: '100%',
      zIndex: Z_INDEX.OVERLAY,
      transform: 'rotate(180deg)',
    },
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 300,
    marginBottom: 30,
    [MQ.TABLET]: {
      margin: 'auto',
      maxWidth: 450,
    },
    [MQ.TABLET_LARGE]: {
      maxWidth: 350,
    },
    [MQ.DESKTOP]: {
      maxWidth: 450,
    },
  },
  annies: {
    height: 125,
    width: '40%',
    [MQ.TABLET_LARGE]: {
      height: 100,
    },
    [MQ.DESKTOP]: {
      height: 125,
    },
  },
  novelEffect: {
    height: 75,
    width: '40%',
    [MQ.TABLET_LARGE]: {
      height: 65,
    },
    [MQ.DESKTOP]: {
      height: 75,
    },
  },
  cross: {
    width: 10,
    height: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [MQ.TABLET]: {
      width: 24,
      height: 24,
    },
    '&:before': {
      position: 'absolute',
      height: 13,
      width: 2,
      backgroundColor: COLOR.DARK_GREY,
      transform: 'rotate(45deg)',
      content: '""',
      borderRadius: 5,
      [MQ.TABLET]: {
        height: 24,
      },
    },
    '&:after': {
      position: 'absolute',
      height: 13,
      width: 2,
      backgroundColor: COLOR.DARK_GREY,
      transform: 'rotate(-45deg)',
      content: '""',
      borderRadius: 5,
      [MQ.TABLET]: {
        height: 24,
      },
    },
  },
  videoWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'absolute',
    [MQ.TABLET]: {
      position: 'static',
      width: '52%',
      height: '100%',
    },
    [MQ.TABLET_LARGE]: {
      width: '60%',
    },
  },

  video: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    [MQ.TABLET]: {
      left: '48%',
      width: '52%',
    },
    [MQ.TABLET_LARGE]: {
      left: '40%',
      width: '60%',
    },
    '& iframe': {
      position: 'absolute',
      width: (props) => props.videoHeight,
      height: '100%',
      margin: 0,
    },
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    [MQ.TABLET]: {
      left: '48%',
      width: '52%',
    },
    [MQ.TABLET_LARGE]: {
      left: '40%',
      width: '60%',
    },
    '& iframe': {
      position: 'absolute',
      width: (props) => props.videoHeight,
      height: '100%',
      margin: 0,
    },
  },
  modalButton: {
    position: 'absolute',
    height: 110,
    width: 110,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: 'none',
    cursor: 'pointer',
    zIndex: Z_INDEX.OVERLAY,
    top: 160,
    [MQ.TABLET]: {
      zIndex: Z_INDEX.OVERLAY,
      position: 'static',
      bottom: 0,
      height: 155,
      width: 150,
      alignSelf: 'center',
    },
    //Changing color here instead of via component so that the wrong
    //color doesn't flash before hydration
    '& div': {
      '& svg': {
        '& circle': {
          fill: COLOR.BLUE,
          [MQ.TABLET]: {
            fill: COLOR.PURPLE,
          },
        },
      },
    },
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 10000,
  },
  modalFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80%',
    [MQ.TABLET_LARGE]: {
      marginTop: '10vh',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    '& iframe': {
      width: '100%',
      height: (props) => props.mobileHeight,
      order: 5,
      [MQ.TABLET_LARGE]: {
        width: (props) => props.width,
        height: (props) => props.modalHeight,
        order: 1,
      },
    },
  },

  closeModal: {
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'none',
    height: (props) => props.button,
    width: (props) => props.button,
    cursor: 'pointer',
    marginTop: 50,
    order: 1,
    outline: 'none',
    [MQ.TABLET_LARGE]: {
      marginTop: 0,
      marginLeft: 25,
    },
  },
  firstLine: {
    position: 'relative',
    height: (props) => props.button,
    width: (props) => props.button,
    '&:before': {
      position: 'absolute',
      content: '""',
      height: 35,
      width: 3,
      backgroundColor: COLOR.ORANGE,
      transform: 'rotate(45deg)',
      [MQ.TABLET_LARGE]: {
        height: 75,
      },
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      height: 35,
      width: 3,
      backgroundColor: COLOR.ORANGE,
      transform: 'rotate(-45deg)',
      [MQ.TABLET_LARGE]: {
        height: 75,
      },
    },
  },
  shortSquiggle: {
    position: 'absolute',
    top: 50,
    left: -23,
    width: 115,
    height: 167,
    zIndex: Z_INDEX.OVERLAY,
    transform: 'rotate(271deg)',
  },
  peace: {
    width: 92,
    height: 92,
    position: 'absolute',
    right: -30,
    top: -70,
    zIndex: Z_INDEX.OVERLAY,

    [MQ.TABLET]: {
      right: -40,
      top: 375,
      width: 75,
      height: 75,
    },
    [MQ.DESKTOP]: {
      top: 465,
      width: 92,
      height: 92,
    },
    [MQ.DESKTOP_LARGE]: {
      height: 120,
      width: 120,
      right: -60,
    },
  },
  mobile: {
    [MQ.TABLET]: {
      display: 'none',
    },
  },
})
