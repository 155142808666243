import * as React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Vimeo from '@u-wave/react-vimeo'
import { useMeasure, useMedia } from 'react-use'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text, Svg, AccessibleText } from '@atoms'
import { COLOR, LINKS } from '@constants'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { DownloadButtons } from '../../molecules'

export const Hero = ({
  button,
  width,
  image,
  modalHeight,
  mobileHeight,
  backgroundHeight,
}) => {
  const [wrapper, { height }] = useMeasure()
  const videoHeight = height * 1.77777
  const classes = useStyles({
    videoHeight,
    button,
    width,
    modalHeight,
    mobileHeight,
    backgroundHeight,
  })

  const imageSrc = getImage(image)

  const [modal, setModal] = useState(false)
  const [bkgPlay, setBkgPlay] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  useEffect(() => {
    return setBkgPlay()
  }, [modal])
  const handleModalOpen = () => {
    setModal(true)
    setBkgPlay(true)
  }
  const handleModalClose = () => {
    setBkgPlay(false)
    setModal(false)
  }

  const handleOnReady = () => {
    setTimeout(function () {
      setIsPlaying(true)
    }, 25)
  }

  const isMobile = useMedia('(max-width: 599px)')

  const content = modal && (
    <div className={classes.modal}>
      <div className={classes.modalFlex}>
        <iframe
          className={classes.modalVideo}
          title="background"
          src="https://player.vimeo.com/video/522085188?controls=1&byline=0&title=0&autoplay=1"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen={true}
          allow="autoplay"
        ></iframe>
        <button className={classes.closeModal} onClick={handleModalClose}>
          <div className={classes.firstLine} />
        </button>
      </div>
    </div>
  )

  return (
    <div className={classes.wrapper} ref={wrapper}>
      {content}
      <div className={classes.firstColumn}>
        <div className={classes.shortSquiggle}>
          <Svg name="short-squiggle" pathFill={COLOR.YELLOW} />
        </div>
        <div className={classes.textBlock}>
          <div className={classes.title}>
            <Text size="header4" tag="h1">
              Make Storytime Magical{' '}
              <span className={classes.mobileTitle}>with</span>
            </Text>
          </div>
          {isMobile && (
            <div className={cn(classes.logoContainer, classes.mobile)}>
              <a
                href={LINKS.ANNIES}
                target="_blank"
                rel="noreferrer"
                className={classes.annies}
              >
                <AccessibleText>
                  Visit the Annie's Homegrown Website
                </AccessibleText>
                <Svg name="annies" />
              </a>
              <div className={classes.cross} />
              <a
                href={LINKS.NOVEL_EFFECT}
                target="_blank"
                rel="noreferrer"
                className={classes.novelEffect}
              >
                <AccessibleText>Visit the Novel Effect Website</AccessibleText>
                <Svg name="novel-effect" />
              </a>
            </div>
          )}

          <DownloadButtons text="DOWNLOAD THE FREE APP" textStyle="button3" />
          <div className={classes.peace}>
            <Svg name="peace" />
          </div>
        </div>

        {!isMobile && (
          <div className={classes.logoWrapper}>
            <div className={classes.logoContainer}>
              <a
                href={LINKS.ANNIES}
                target="_blank"
                rel="noreferrer"
                className={classes.annies}
              >
                <AccessibleText>
                  Visit the Annie's Homegrown Website
                </AccessibleText>
                <Svg name="annies" />
              </a>
              <div className={classes.cross} />
              <a
                href={LINKS.NOVEL_EFFECT}
                target="_blank"
                rel="noreferrer"
                className={classes.novelEffect}
              >
                <AccessibleText>Visit the Novel Effect Website</AccessibleText>
                <Svg name="novel-effect" />
              </a>
            </div>
          </div>
        )}
      </div>
      <div className={classes.videoWrapper}>
        <button
          className={classes.modalButton}
          onClick={handleModalOpen}
          aria-label="Play Video"
        >
          <Svg name="play-button" />
        </button>
        <Vimeo
          video="522083062"
          className={classes.video}
          background
          paused={bkgPlay}
          loop
          onPlay={handleOnReady}
          // onReady={handleOnReady}
        />
        {!isPlaying && (
          <GatsbyImage
            image={imageSrc}
            alt="placeholder"
            className={classes.image}
            loading="eager"
          />
        )}
      </div>
    </div>
  )
}

Hero.propTypes = {
  text: PropTypes.string,
}

Hero.defaultProps = {
  text: 'Hero',
}
