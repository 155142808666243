import * as React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { useStyles } from './styles'

export const WaveBackground = ({ color, type }) => {
  const classes = useStyles({ color })

  const wrapperClasses = cn({
    [classes.wrapper]: true,
    [classes.novelEffectWrapper]: type === 'novelEffect',
  })

  return (
    <div className={wrapperClasses}>
      <div className={classes.background} />
    </div>
  )
}

WaveBackground.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
}

WaveBackground.defaultProps = {
  color: '#ffffff',
  type: 'homegrown',
}
