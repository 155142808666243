import * as React from 'react'

export const shortSquiggle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="190.5"
    height="275.85"
    viewBox="0 0 190.5 275.85"
  >
    <path
      d="M130.43,273.86a64,64,0,0,1-31.6-8.27A55.22,55.22,0,0,1,70.75,221.5c-1.26-17,5.26-34.32,17.3-45.35,12.29-11.27,26.83-13.52,39.62-15.78,2.76-.5,5.27-.75,7.77-1.25,4.27-.75,9.53-2,12-4.76a12,12,0,0,0,3-7.52c.75-10.27-7.52-15.28-11.29-16.78-11.28-5-24.57-4.76-38.61-4.51-16.3.25-34.86.75-52.66-7.52S17.34,94.73,9.81,73.94c-5-14.28-7.77-31.57-8-52.61a19.12,19.12,0,0,1,9-16.54,19.53,19.53,0,0,1,19.56-.25,18.81,18.81,0,0,1,9.78,16.29c.25,16,1.25,34.07,9.27,48.1C55.7,80,65.73,86,79,87.47c7,.75,14.8.5,22.82.5,22.07-.25,46.89-.75,67.71,16.28a53.63,53.63,0,0,1,18.8,47.85c-2,17.29-11.78,31.32-26.83,38.59-9,4.25-18.3,5.76-26.58,7-5.77,1-11.28,1.75-16,3.51-5.76,2.25-9.53,7.76-10,14.78a17.24,17.24,0,0,0,8,15.78,24.5,24.5,0,0,0,23.82,1.51,19.19,19.19,0,0,1,19.06,1.25c5.76,4,9.28,10.52,8.77,17.28a18.53,18.53,0,0,1-10.28,15.29A57.8,57.8,0,0,1,130.43,273.86Z"
      fill="#ef6c52"
    />
  </svg>
)
