import * as React from 'react'

export const novelEffect = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="183.93"
      height="73.08"
      viewBox="0 0 183.93 73.08"
    >
      <path
        d="M35.2,9.05a14.21,14.21,0,0,0-24.26,10.1v28a14.24,14.24,0,1,0,28.48,0V19.08A14.56,14.56,0,0,0,35.2,9.05ZM25.12,56A10.45,10.45,0,0,1,14.7,45.56h2.77a7.62,7.62,0,0,0,15.23,0h2.77A10.36,10.36,0,0,1,25.12,56Z"
        fill="#42aab5"
      />
      <path
        d="M25.12,70.59A25.22,25.22,0,0,1,0,45.43H4.22A20.9,20.9,0,1,0,46,46v-.53h4.22A25.17,25.17,0,0,1,25.12,70.59Z"
        fill="#42aab5"
      />
      <path
        d="M55,8.72v3.17h.13A7.32,7.32,0,0,1,58.21,9a8.74,8.74,0,0,1,3.89-.92,9.94,9.94,0,0,1,4.15.72,6,6,0,0,1,2.51,1.92,7.11,7.11,0,0,1,1.32,3,19.07,19.07,0,0,1,.39,3.83V31.56H64.21V18.62a7.85,7.85,0,0,0-.86-4.22A3.45,3.45,0,0,0,60.19,13a4.3,4.3,0,0,0-3.69,1.52c-.79,1-1.12,2.7-1.12,5v12H49.11V8.72Z"
        fill="#15212b"
      />
      <path
        d="M75.35,15.19A10.46,10.46,0,0,1,81.42,9a13.35,13.35,0,0,1,4.87-.86A13.63,13.63,0,0,1,91.17,9a10.53,10.53,0,0,1,6.13,6.21,14.06,14.06,0,0,1,.86,5,13.79,13.79,0,0,1-.86,4.95,10.7,10.7,0,0,1-2.37,3.77,9.38,9.38,0,0,1-3.76,2.37,14.25,14.25,0,0,1-9.75,0,10.43,10.43,0,0,1-3.7-2.37,10.85,10.85,0,0,1-2.37-3.77,14,14,0,0,1-.86-4.95A13,13,0,0,1,75.35,15.19ZM81,22.85a5.48,5.48,0,0,0,.92,2.31,4.63,4.63,0,0,0,1.72,1.65,4.54,4.54,0,0,0,2.63.59,5.87,5.87,0,0,0,2.64-.59,4.63,4.63,0,0,0,1.72-1.65,7.86,7.86,0,0,0,.92-2.31,11.88,11.88,0,0,0,.26-2.64,12.39,12.39,0,0,0-.26-2.71,5.48,5.48,0,0,0-.92-2.31,5.86,5.86,0,0,0-1.72-1.65,5.13,5.13,0,0,0-2.64-.66,4.82,4.82,0,0,0-4.35,2.31A7.76,7.76,0,0,0,81,17.5a12,12,0,0,0-.26,2.71A12.29,12.29,0,0,0,81,22.85Z"
        fill="#15212b"
      />
      <path
        d="M107.79,31.56,100,8.72h6.59l4.81,15.58h.07l4.81-15.58h6.2l-7.72,22.84Z"
        fill="#15212b"
      />
      <path
        d="M131.78,26.08a5.55,5.55,0,0,0,4.09,1.39,6.41,6.41,0,0,0,3.3-.92,4,4,0,0,0,1.64-2h5.54a10.21,10.21,0,0,1-4.09,5.88,11.83,11.83,0,0,1-6.59,1.78,13.94,13.94,0,0,1-4.88-.85,10.69,10.69,0,0,1-3.69-2.45,12.27,12.27,0,0,1-2.31-3.76,14.16,14.16,0,0,1-.79-4.89,14.57,14.57,0,0,1,.79-4.75,11,11,0,0,1,6.07-6.34,11.74,11.74,0,0,1,4.75-.93,10.26,10.26,0,0,1,8.63,4.16,12,12,0,0,1,2,4.36,16.71,16.71,0,0,1,.46,5.09H130.2A6.49,6.49,0,0,0,131.78,26.08Zm7.12-12a4.49,4.49,0,0,0-3.43-1.26,5.47,5.47,0,0,0-2.5.53,5,5,0,0,0-1.58,1.25,4.63,4.63,0,0,0-.86,1.59,5.69,5.69,0,0,0-.26,1.52h10.21A7.66,7.66,0,0,0,138.9,14.07Z"
        fill="#15212b"
      />
      <path d="M156.83,0V31.56h-6.26V0Z" fill="#15212b" />
      <path
        d="M70.74,63a5.57,5.57,0,0,0,4.08,1.38,6.41,6.41,0,0,0,3.3-.92,4,4,0,0,0,1.65-2h5.54a10.22,10.22,0,0,1-4.09,5.87,11.86,11.86,0,0,1-6.59,1.79,13.91,13.91,0,0,1-4.88-.86,10.62,10.62,0,0,1-3.69-2.44A12,12,0,0,1,63.75,62,12.31,12.31,0,0,1,63,57.12a13,13,0,0,1,.85-4.76A11,11,0,0,1,69.88,46a11.57,11.57,0,0,1,4.75-.93,10.34,10.34,0,0,1,5.07,1.12,10.56,10.56,0,0,1,3.56,3,11.9,11.9,0,0,1,2,4.36,16.67,16.67,0,0,1,.46,5.08H69.22A6.23,6.23,0,0,0,70.74,63Zm7.05-12a4.29,4.29,0,0,0-3.43-1.26,5.57,5.57,0,0,0-2.5.53,4.93,4.93,0,0,0-1.59,1.26,4.73,4.73,0,0,0-.85,1.58,5.69,5.69,0,0,0-.27,1.52H79.31A6.16,6.16,0,0,0,77.79,51Z"
        fill="#15212b"
      />
      <path
        d="M87,49.86V45.63h3.76V43.85a7,7,0,0,1,1.91-5c1.26-1.32,3.17-1.92,5.74-1.92A13,13,0,0,1,100,37c.52.06,1.12.06,1.64.13V41.8c-.79-.07-1.51-.13-2.3-.13a2.52,2.52,0,0,0-1.85.59,2.78,2.78,0,0,0-.53,2v1.39h4.35v4.23H97V68.48H90.71V49.79H87Z"
        fill="#15212b"
      />
      <path
        d="M103.63,49.86V45.63h3.76V43.85a7,7,0,0,1,1.91-5c1.25-1.32,3.17-1.92,5.74-1.92a13.09,13.09,0,0,1,1.65.07c.52.06,1.12.06,1.64.13V41.8c-.72-.07-1.51-.13-2.3-.13a2.52,2.52,0,0,0-1.85.59,2.73,2.73,0,0,0-.53,2v1.39H118v4.23h-4.35V68.48h-6.26V49.79h-3.76Z"
        fill="#15212b"
      />
      <path
        d="M127.43,63a5.59,5.59,0,0,0,4.09,1.38,6.37,6.37,0,0,0,3.29-.92,4,4,0,0,0,1.65-2H142a10.18,10.18,0,0,1-4.09,5.87,11.83,11.83,0,0,1-6.59,1.79,13.91,13.91,0,0,1-4.88-.86,10.8,10.8,0,0,1-3.69-2.44A12,12,0,0,1,120.44,62a12.68,12.68,0,0,1-.79-4.89,13,13,0,0,1,.86-4.76A11.22,11.22,0,0,1,126.57,46a11.57,11.57,0,0,1,4.75-.93,10,10,0,0,1,5,1.12,10.65,10.65,0,0,1,3.56,3,11.9,11.9,0,0,1,2,4.36,16.67,16.67,0,0,1,.46,5.08H125.91A6.28,6.28,0,0,0,127.43,63Zm7-12a4.27,4.27,0,0,0-3.42-1.26,5.61,5.61,0,0,0-2.51.53A4.9,4.9,0,0,0,127,51.51a4.58,4.58,0,0,0-.86,1.58,5.69,5.69,0,0,0-.26,1.52h10.22A6.58,6.58,0,0,0,134.48,51Z"
        fill="#15212b"
      />
      <path
        d="M157,49.72a4.75,4.75,0,0,0-2.5.66,5.45,5.45,0,0,0-1.65,1.79,7.5,7.5,0,0,0-.92,2.37,11.31,11.31,0,0,0-.27,2.58,10.33,10.33,0,0,0,.27,2.51,7.44,7.44,0,0,0,.86,2.31,5.61,5.61,0,0,0,1.58,1.72,4.52,4.52,0,0,0,2.44.66,4.63,4.63,0,0,0,3.49-1.26,5.81,5.81,0,0,0,1.52-3.37h6.06a10.13,10.13,0,0,1-3.56,6.94A11.47,11.47,0,0,1,156.83,69a12.6,12.6,0,0,1-4.68-.85,10.56,10.56,0,0,1-3.62-2.38,11.33,11.33,0,0,1-2.31-3.7,12.89,12.89,0,0,1-.86-4.69,16.11,16.11,0,0,1,.79-5,12,12,0,0,1,2.24-4,10.56,10.56,0,0,1,3.7-2.58A11.49,11.49,0,0,1,157,45a15.33,15.33,0,0,1,3.89.53,11.22,11.22,0,0,1,3.36,1.58,8.52,8.52,0,0,1,3.5,6.54h-6.13A4.31,4.31,0,0,0,157,49.72Z"
        fill="#15212b"
      />
      <path
        d="M183.93,45.63v4.23h-4.55V61.15a3.14,3.14,0,0,0,.53,2.11,3.12,3.12,0,0,0,2.11.53,4.3,4.3,0,0,0,1-.07,4.25,4.25,0,0,0,.93-.13v4.89l-1.78.19c-.66,0-1.25.07-1.91.07a19.74,19.74,0,0,1-2.77-.2,7.2,7.2,0,0,1-2.31-.79,4.19,4.19,0,0,1-1.58-1.65,6.71,6.71,0,0,1-.6-2.77V49.86h-3.82V45.63H173V38.76h6.27v6.87Z"
        fill="#15212b"
      />
    </svg>
  )
}
