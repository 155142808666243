import { createUseStyles } from 'react-jss'

import { MQ } from '@constants'

export const useStyles = createUseStyles({
  buttonGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 300,
    margin: 'auto',
    [MQ.TABLET]: {
      margin: 0,
      alignItems: 'flex-start',
      maxWidth: 320,
      width: '90%',
    },
  },
  section1: {
    margin: 'auto',
    [MQ.DESKTOP]: {
      margin: 0,
    },
  },
  storeLinks: {
    width: '100%',
    margin: [30, 0, 40],
    display: 'flex',
    justifyContent: 'center',
    [MQ.TABLET]: {
      marginBottom: 124,
      justifyContent: 'space-between',
    },
    [MQ.TABLET_LARGE]: {
      margin: [30, 0, 50],
    },
  },
  section1Links: {
    marginBottom: 0,
    [MQ.TABLET]: {
      marginBottom: 0,
    },
  },
  section3Links: {
    marginBottom: 0,
    [MQ.TABLET]: {
      marginBottom: 0,
    },
  },
  store: {
    width: '37%',
    height: 45,
    padding: [5, 10],
    margin: [0, 10],
    backgroundColor: (props) => props.buttonColor,
    borderRadius: 10,
    [MQ.TABLET]: {
      margin: [0, 20, 0, 0],
      width: '43%',
    },
  },
  download: {
    color: (props) => props.textColor,
  },
})
