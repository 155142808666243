import * as React from 'react'

export const three = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="164.27"
    height="176.05"
    viewBox="0 0 164.27 176.05"
  >
    <path
      d="M40.75,174.13h-.82a16.85,16.85,0,0,1-11-5.49,103.41,103.41,0,0,1-22.39-38C1.25,114.54.85,98.68,4.92,85.05c3.05-10,12-22.16,36.84-23.59a84.88,84.88,0,0,1,12.42.21A72.16,72.16,0,0,1,56,36.45,43.43,43.43,0,0,1,77.19,8.79C91,1.26,109.35,0,126.65,5.53,140.7,10,162.89,25.06,162.28,46a16.91,16.91,0,0,1-5.91,12.6,15.31,15.31,0,0,1-22-2.84,16,16,0,0,1-2.85-7.32v-.21c-1-5.08-6.11-10.17-13-13a36,36,0,0,0-26.46.2c-5.5,2.24-6.72,12-6.93,16.27-.4,8.34,1.63,15,6.52,19.73,1.63,1.62,3.46,3,5.29,4.68,5.9,5.08,12.62,10.77,16.69,19.72A16,16,0,0,1,107.11,116a14.65,14.65,0,0,1-19.34-3.66A52.27,52.27,0,0,0,66.4,96a53,53,0,0,0-23-4.07c-3.66.2-6.51,1-7.73,2.24C34.23,95.63,34,99.29,34,101.32a71,71,0,0,0,16.08,43.93l.2.21a22.07,22.07,0,0,1,5.3,10.78,16.06,16.06,0,0,1-4.07,13A14.11,14.11,0,0,1,40.75,174.13Z"
      fill="#a368a5"
    />
  </svg>
)
