import * as React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { useStyles } from './styles'
import { Svg, AccessibleText, Text } from '@atoms'
import { LINKS, COLOR } from '@constants'
import { getOperatingSystemLink } from '@helpers'

export const DownloadButtons = ({
  text,
  page,
  textStyle,
  textColor,
  buttonColor,
}) => {
  const classes = useStyles({ textColor, buttonColor })

  const downloadButtonLink = getOperatingSystemLink()

  const containerClasses = cn({
    [classes.buttonGrid]: true,
    [classes.section1]: page === 'section1',
  })
  const storeLinksClasses = cn({
    [classes.storeLinks]: true,
    [classes.section1Links]: page === 'section1',
    [classes.section3Links]: page === 'section3',
  })

  return (
    <div className={containerClasses}>
      <Text size={textStyle} className={classes.download}>
        {text}
      </Text>
      <div className={storeLinksClasses}>
        <a
          className={classes.store}
          href={downloadButtonLink ? downloadButtonLink : LINKS.APPLE}
          target="_blank"
          rel="noreferrer"
        >
          <AccessibleText>Download the iPhone App</AccessibleText>
          <Svg name="apple-download" />
        </a>
        <a
          className={classes.store}
          href={downloadButtonLink ? downloadButtonLink : LINKS.ANDROID}
          target="_blank"
          rel="noreferrer"
        >
          <AccessibleText>Download the Android App</AccessibleText>
          <Svg name="android-download" />
        </a>
      </div>
    </div>
  )
}

DownloadButtons.propTypes = {
  text: PropTypes.string,
  page: PropTypes.string,
  textStyle: PropTypes.string,
  textColor: PropTypes.string,
  buttonColor: PropTypes.string,
}

DownloadButtons.defaultProps = {
  text: 'DOWNLOAD THE FREE APP',
  page: '',
  textStyle: 'button3',
  textColor: COLOR.WHITE,
  buttonColor: COLOR.BLUE,
}
