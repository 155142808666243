import React from 'react'

import { Svg, Text, AccessibleText } from '@atoms'
import { LINKS } from '@constants'
import { useStyles } from './styles'

export const Footer = () => {
  const classes = useStyles()

  return (
    <div className={classes.footerWrapper}>
      <div className={classes.footerContainer}>
        <div className={classes.social}>
          <a
            href={LINKS.INSTAGRAM}
            target="_blank"
            rel="noreferrer"
            className={classes.instagram}
          >
            <AccessibleText>Visit the Novel Effect Instagram</AccessibleText>
            <Svg name="instagram" svgFill="#000" />
          </a>
          <a
            href={LINKS.FACEBOOK}
            target="_blank"
            rel="noreferrer"
            className={classes.facebook}
          >
            <AccessibleText>Visit the Novel Effect Facebook</AccessibleText>
            <Svg name="facebook" svgFill="#000" />
          </a>
        </div>
        <div className={classes.logoContainer}>
          <a
            href={LINKS.ANNIES}
            target="_blank"
            rel="noreferrer"
            className={classes.annies}
          >
            <AccessibleText>Visit the Annie's Homegrown Website</AccessibleText>
            <Svg name="annies" />
          </a>
          <div className={classes.cross} />
          <a
            href={LINKS.NOVEL_EFFECT}
            target="_blank"
            rel="noreferrer"
            className={classes.novelEffect}
          >
            <AccessibleText>Visit the Novel Effect Website</AccessibleText>
            <Svg name="novel-effect" />
          </a>
        </div>
        <div className={classes.linkContainer}>
          <a
            href={LINKS.SUBSCRIBE}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            <Text size="body2">PREMIUM</Text>
          </a>
          <a
            href={LINKS.CONTACT}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            <Text size="body2">CONTACT</Text>
          </a>
        </div>
      </div>
      <div className={classes.copyright}>
        <Text size="body3" tag="p">
          &copy; Novel Effect x Annie’s&#8482; Homegrown, 2021
        </Text>
        <Text size="body3" tag="p">
          Website by{' '}
          <a
            className={classes.footerLink}
            href="https://riffcreativestudio.com"
            target="_blank"
            rel="noreferrer"
          >
            Riff_ Creative
          </a>
        </Text>
      </div>
    </div>
  )
}
