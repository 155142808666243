import * as React from 'react'

export const star = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="170.21"
    height="179.17"
    viewBox="0 0 170.21 179.17"
  >
    <path
      d="M88.68,4.9l9.5,32.79a3.81,3.81,0,0,0,6.1,2l26.81-20.8a3.9,3.9,0,0,1,6.1,4.4l-11,32.07a4,4,0,0,0,3.9,5.38l33.88-1a4.07,4.07,0,0,1,2.44,7.35l-28,19.09a4.13,4.13,0,0,0,0,6.6l28,19.34a4,4,0,0,1-2.44,7.34l-33.88-1a4.1,4.1,0,0,0-3.9,5.39l11.45,32.06a3.92,3.92,0,0,1-6.09,4.41l-26.81-20.81a3.82,3.82,0,0,0-6.1,2l-9.51,32.8c-1.21,3.67-6.58,3.67-7.55,0l-10-32.8a3.81,3.81,0,0,0-6.09-2L38.71,160.32a3.9,3.9,0,0,1-6.1-4.41l11.46-32.06a4,4,0,0,0-3.9-5.39L6,119.2a4.07,4.07,0,0,1-2.43-7.34l28-19.34a4.14,4.14,0,0,0,0-6.61l-28-19.09A4,4,0,0,1,6,59.48L39.93,60.7a4.1,4.1,0,0,0,3.9-5.38L32.37,23.25a3.91,3.91,0,0,1,6.09-4.4l26.82,20.8a3.8,3.8,0,0,0,6.09-2L80.88,4.9C82.34,1,87.7,1,88.68,4.9Z"
      fill="#e13f3f"
    />
  </svg>
)
