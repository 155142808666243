import { createUseStyles } from 'react-jss'

import { Z_INDEX, MQ } from '@constants'
import smallBackground from '@images/smallBackground.svg'
import background from '@images/background.svg'

import mobileWave from '@images/mobileWave-05.svg'
import mobileWave180 from '@images/mobileWave180.svg'
import wave from '@images/wave.svg'
import wave180 from '@images/wave180-04.svg'

export const useStyles = createUseStyles({
  wrapper: {
    backgroundColor: (props) => props.color,
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: Z_INDEX.BACKGROUND,

    '&:before': {
      background: `url("${mobileWave180}") center`,
      content: '""',
      position: 'absolute',
      height: 15,
      left: 0,
      top: 0,
      width: '100%',
      zIndex: Z_INDEX.OVERLAY,
      [MQ.TABLET]: {
        background: `url("${wave180}") center`,
        height: 28,
      },
    },
    '&:after': {
      background: `url("${mobileWave}") center`,
      content: '""',
      position: 'absolute',
      height: 15,
      left: 0,
      bottom: -1,
      width: '100%',
      zIndex: Z_INDEX.OVERLAY,
      [MQ.TABLET]: {
        background: `url("${wave}") top center`,
        height: 28,
      },
    },
  },
  novelEffectWrapper: {
    height: '90%',
    [MQ.TABLET]: {
      height: '80%',
    },
    [MQ.DESKTOP]: {
      height: '85%',
    },
  },
  wave: {
    zIndex: Z_INDEX.OVERLAY,
  },
  background: {
    position: 'relative',
    zIndex: Z_INDEX.BACKGROUND,
    width: '100%',
    height: '100%',
    opacity: 0.8,
    backgroundImage: `url("${smallBackground}")`,
    [MQ.DESKTOP]: {
      backgroundImage: `url("${background}")`,
    },
  },
})
