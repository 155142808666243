import * as React from 'react'

export const playButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="174.39"
    height="173.9"
    viewBox="0 0 174.39 173.9"
  >
    <circle
      cx="87.4"
      cy="86.29"
      r="85"
      fill="#a368a5"
      opacity="0.3"
      style={{ isolation: 'isolate' }}
    />
    <circle
      cx="87.2"
      cy="86.95"
      r="68.04"
      fill="#a368a5"
      style={{ isolation: 'isolate' }}
    />
    <path d="M113.53,87,64.78,115.09V58.8Z" fill="#fff" />
  </svg>
)
