import * as React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useMeasure } from 'react-use'

import { useStyles } from './styles'
import { WaveBackground, Text, Button } from '@atoms'
import { COLOR, LINKS } from '@constants'

export const NovelEffect = ({ novelEffect1, novelEffect2 }) => {
  const [image, imageSize] = useMeasure()
  const [secondImage, secondSize] = useMeasure()
  const columnHeight = imageSize.height + secondSize.height
  const classes = useStyles({ columnHeight })
  const novelEffectImage1 = getImage(novelEffect1)
  const novelEffectImage2 = getImage(novelEffect2)

  return (
    <div className={classes.wrapper}>
      <WaveBackground color={COLOR.BLUE} type="novelEffect" />
      <div className={classes.content}>
        <div className={classes.firstSection}>
          <div className={classes.title}>
            <Text size="header1" tag="h2">
              Become a story time superhero
            </Text>
          </div>
          <div className={classes.subTitle}>
            <Text tag="h3" size="header2">
              Novel Effect brings your favorite stories to life in a whole new
              way
            </Text>
          </div>
          <div className={classes.body}>
            <Text tag="p" size="body1">
              As you read aloud, our free, award-winning app follows along and
              plays music, sound effects, and character voices at just the right
              moments to transform the reading experience and bring the story to
              life. Dive into the Annie's&#8482; stories in the time it takes
              you to make mac &amp; cheese and explore the Novel Effect library
              to discover hundreds of other books available for your story time.
            </Text>
          </div>
          <div className={classes.linkContainer}>
            <Button
              color={COLOR.PURPLE}
              text="START STORYTELLING"
              link={LINKS.NOVEL_EFFECT}
            />
          </div>
        </div>
        <div className={classes.image} ref={image}>
          <GatsbyImage image={novelEffectImage2} alt="image" />
        </div>
        <div className={classes.secondImage} ref={secondImage}>
          <GatsbyImage image={novelEffectImage1} alt="image" />
        </div>
      </div>
    </div>
  )
}

NovelEffect.propTypes = {
  novelEffect1: PropTypes.object,
  novelEffect2: PropTypes.object,
}

NovelEffect.defaultProps = {
  novelEffect1: null,
  novelEffect2: null,
}
