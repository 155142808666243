import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    contentVisibility: 'auto',
  },
  textBlock: {
    textAlign: 'center',
    margin: 'auto',
    marginTop: 140,
    width: '80%',
    [MQ.TABLET]: {
      marginTop: 50,
    },
    [MQ.TABLET_LARGE]: {
      width: '60%',
    },
    [MQ.DESKTOP]: {
      width: '46%',
      marginTop: 75,
    },
    [MQ.DESKTOP_LARGE]: {
      marginTop: 100,
    },
  },
  body: {
    padding: [30, 0, 0, 0],
    textAlign: 'left',
    [MQ.TABLET]: {
      textAlign: 'center',
    },
    [MQ.TABLET_LARGE]: {
      padding: [35, 0, 0, 0],
      margin: 'auto',
      maxWidth: 610,
    },
  },
  button: {
    backgroundColor: COLOR.BLUE,
    color: COLOR.WHITE,
    border: 'none',
    borderRadius: 5,
    padding: [9, 31],
    cursor: 'pointer',
    [MQ.DESKTOP]: {
      padding: [13, 51],
    },
  },
  imageContainer: {
    width: '90%',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    padding: [0, 0, 90, 0],
    justifyContent: 'center',
    maxWidth: 730,

    [MQ.TABLET]: {
      maxWidth: 'none',
      justifyContent: 'space-around',
      width: '70%',
      padding: [100, 0, 157, 0],
    },
    [MQ.DESKTOP]: {
      padding: [75, 0, 125, 0],
    },
    [MQ.DESKTOP_LARGE]: {
      padding: [100, 0, 150, 0],
      maxWidth: 1395,
    },
    '& div:nth-child(4)': {
      display: 'none',
      [MQ.TABLET]: {
        display: 'block',
      },
    },
  },
  image: {
    width: '30%',
    margin: [45, 5, 0, 5],
    [MQ.TABLET]: {
      margin: 0,
      width: '23%',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [MQ.TABLET]: {
      flexDirection: 'row',
      margin: [60, 0, 0],
    },
    [MQ.DESKTOP]: {
      margin: [50, 0, 0],
    },
    '& a': {
      marginTop: 30,
      [MQ.TABLET]: {
        margin: [0, 15],
      },
    },
  },
})
