import { createUseStyles } from 'react-jss'

import { MQ, COLOR } from '@constants'

export const useStyles = createUseStyles({
  button: {
    backgroundColor: (props) => props.color,
    display: 'inline-block',
    color: COLOR.WHITE,
    textDecoration: 'none',
    borderRadius: 5,
    textAlign: 'center',
    padding: [13, 21, 12, 21],
    [MQ.DESKTOP]: {
      padding: [17, 25, 13, 25],
    },
  },
  wideButton: {
    padding: [10, 30, 8, 30],
    [MQ.DESKTOP]: {
      padding: [17, 51, 13, 51],
    },
  },
  noLink: {
    backgroundColor: (props) => 'rgba( 0, 0, 0, 0) ',
    color: (props) => (props.whiteBkg ? COLOR.BLACK : COLOR.WHITE),
    padding: 0,
    textAlign: 'left',
  },
})
