import * as React from 'react'

export const facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <path d="M13.5,0A13.5,13.5,0,1,0,27,13.5,13.47,13.47,0,0,0,13.5,0Zm0,2.08A11.52,11.52,0,0,1,24.92,13.5a11.23,11.23,0,0,1-9.76,11.22v-7.9h3.32l.42-3.32H15.16V11.84c0-1.46.42-2.49,1.66-2.49H18.9V6.23H16.41c-3.12,0-4.78,1.45-4.78,5.19V13.5H8.52v3.32h3.11v7.9A11.4,11.4,0,0,1,2.08,13.5,11.52,11.52,0,0,1,13.5,2.08Z" />
    </svg>
  )
}
