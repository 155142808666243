import { createUseStyles } from 'react-jss'
import { TYPOGRAPHY, MQ } from '@constants'

export const useStyles = createUseStyles({
  example: {
    ...TYPOGRAPHY.EXAMPLE,
    lineHeight: 1.5,
  },
  header1: {
    ...TYPOGRAPHY.LOTA_SEMI_BOLD,
    lineHeight: 1,
    fontSize: 40,
    letterSpacing: '-0.025em',

    [MQ.TABLET_LARGE]: {
      fontSize: 60,
    },
    [MQ.DESKTOP]: {
      fontSize: 80,
    },
  },
  header2: {
    ...TYPOGRAPHY.LOTA_BOLD,
    fontSize: 24,
    fontStyle: 'normal',

    [MQ.DESKTOP]: {
      fontSize: 30,
    },
  },
  header3: {
    ...TYPOGRAPHY.LOTA_BOLD,
    fontSize: 14,
    lineHeight: 1.8,
    [MQ.DESKTOP]: {
      fontSize: 18,
    },
  },
  header4: {
    ...TYPOGRAPHY.LOTA_SEMI_BOLD,
    lineHeight: 1,
    fontSize: 36,
    [MQ.TABLET]: {
      fontSize: 60,
    },
    [MQ.TABLET_LARGE]: {
      fontSize: 70,
    },
    [MQ.DESKTOP]: {
      fontSize: 102,
    },
  },
  button1: {
    ...TYPOGRAPHY.LOTA_BOLD,
    fontSize: 14,
    letterSpacing: '0.055em',

    [MQ.TABLET_LARGE]: {
      fontSize: 21,
    },
  },
  button2: {
    ...TYPOGRAPHY.LOTA_BOLD,
    letterSpacing: '0.055em',
    fontSize: 21,
  },
  button3: {
    ...TYPOGRAPHY.LOTA_BOLD,
    fontSize: 18,
    letterSpacing: '0.055em',

    [MQ.TABLET_LARGE]: {
      fontSize: 21,
    },
  },

  body1: {
    ...TYPOGRAPHY.LOTA_REGULAR,
    fontSize: 16,
    lineHeight: 1.8,
    letterSpacing: '-0.015em',

    [MQ.DESKTOP]: {
      fontSize: 18,
    },
  },
  body2: {
    ...TYPOGRAPHY.LOTA_BOLD,
    fontSize: 10,
  },
  body3: {
    ...TYPOGRAPHY.LOTA_REGULAR,
    fontSize: 12,
    [MQ.DESKTOP]: {
      fontSize: 15,
    },
  },
  body4: {
    ...TYPOGRAPHY.LOTA_REGULAR,
    fontSize: 20,
    letterSpacing: '-0.015em',
    lineHeight: 1.5,
  },
})
