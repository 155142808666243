import { createUseStyles } from 'react-jss'

export const globalStyles = createUseStyles({
  '@global': {
    html: {
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    'h1, h2, h3, h4, h5, h6': {
      margin: 0,
    },
    div: {
      boxSizing: 'border-box',
    },
    p: {
      marginTop: 0,
    },
  },
})
