import * as React from 'react'

export const appleDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="122.88"
    height="43.77"
    viewBox="0 0 122.88 43.77"
  >
    <path
      d="M111.63,1.75H9.94L9,1.77a13.63,13.63,0,0,0-2,.17,7.07,7.07,0,0,0-1.9.63A6.66,6.66,0,0,0,3.49,3.75,6.26,6.26,0,0,0,2.32,5.37a6.44,6.44,0,0,0-.63,1.9,13.65,13.65,0,0,0-.18,2c0,.3,0,.61,0,.92V33.31c0,.31,0,.61,0,.92a13.65,13.65,0,0,0,.18,2,6.44,6.44,0,0,0,.63,1.9,6.41,6.41,0,0,0,2.79,2.79,6.44,6.44,0,0,0,1.9.63,13.65,13.65,0,0,0,2,.18H113.64a13.39,13.39,0,0,0,2-.18,6.5,6.5,0,0,0,1.91-.63,6.19,6.19,0,0,0,1.61-1.18,6.28,6.28,0,0,0,1.19-1.61,6.4,6.4,0,0,0,.61-1.9,12.43,12.43,0,0,0,.19-2v-.92c0-.36,0-.72,0-1.09V11.29c0-.37,0-.73,0-1.09V9.28a12.43,12.43,0,0,0-.19-2,6.4,6.4,0,0,0-.61-1.9,6.53,6.53,0,0,0-2.8-2.8,7.26,7.26,0,0,0-1.91-.63,12.18,12.18,0,0,0-2-.17l-.92,0Z"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
    />
    <path
      d="M26.49,21.64a5.17,5.17,0,0,1,2.45-4.32,5.27,5.27,0,0,0-4.15-2.24c-1.74-.18-3.44,1-4.33,1s-2.27-1-3.75-1A5.48,5.48,0,0,0,12.06,18c-2,3.48-.51,8.6,1.42,11.41,1,1.38,2.09,2.92,3.56,2.86s2-.92,3.72-.92,2.23.92,3.74.89S27,30.82,28,29.43a11.3,11.3,0,0,0,1.58-3.21,5,5,0,0,1-3-4.58Z"
      fill="#fff"
    />
    <path
      d="M23.65,13.23A5,5,0,0,0,24.81,9.6a5.13,5.13,0,0,0-3.33,1.73,4.7,4.7,0,0,0-1.19,3.49,4.28,4.28,0,0,0,3.36-1.59Z"
      fill="#fff"
    />
    <path
      d="M43.8,28.89H39.07l-1.14,3.36h-2l4.49-12.42h2.08L47,32.25h-2Zm-4.24-1.55h3.75L41.46,21.9h-.05Z"
      fill="#fff"
    />
    <path
      d="M56.66,27.72c0,2.82-1.51,4.62-3.78,4.62A3,3,0,0,1,51.23,32a3.08,3.08,0,0,1-1.2-1.2h0v4.48H48.13v-12h1.8v1.5h0a3.2,3.2,0,0,1,2.88-1.6C55.14,23.1,56.66,24.92,56.66,27.72Zm-1.91,0c0-1.83-1-3-2.4-3S50,25.91,50,27.72s1,3.05,2.37,3.05,2.4-1.2,2.4-3.05Z"
      fill="#fff"
    />
    <path
      d="M66.62,27.72c0,2.82-1.5,4.62-3.78,4.62A3.07,3.07,0,0,1,61.19,32a3.14,3.14,0,0,1-1.2-1.2h0v4.48H58.09v-12h1.8v1.5h0a3.2,3.2,0,0,1,2.88-1.6C65.11,23.1,66.62,24.92,66.62,27.72Zm-1.91,0c0-1.83-.95-3-2.39-3s-2.38,1.23-2.38,3,1,3.05,2.38,3.05S64.71,29.57,64.71,27.72Z"
      fill="#fff"
    />
    <path
      d="M73.21,28.79c.13,1.23,1.33,2,3,2s2.69-.81,2.69-1.92-.68-1.54-2.29-1.94L75,26.59C72.69,26,71.63,25,71.63,23.24c0-2.14,1.87-3.62,4.52-3.62s4.42,1.48,4.48,3.62H78.76c-.11-1.24-1.14-2-2.64-2S73.6,22,73.6,23.11c0,.88.66,1.39,2.26,1.79l1.37.34c2.54.6,3.6,1.62,3.6,3.44C80.83,31,79,32.46,76,32.46c-2.76,0-4.62-1.43-4.74-3.67Z"
      fill="#fff"
    />
    <path
      d="M84.84,21.05V23.2h1.72v1.47H84.84v5c0,.77.35,1.13,1.1,1.13a4.3,4.3,0,0,0,.62,0v1.46a4.52,4.52,0,0,1-1,.09c-1.83,0-2.54-.69-2.54-2.44V24.67H81.66V23.2H83V21.05Z"
      fill="#fff"
    />
    <path
      d="M87.56,27.72c0-2.85,1.68-4.64,4.29-4.64s4.3,1.79,4.3,4.64-1.66,4.64-4.3,4.64S87.56,30.58,87.56,27.72Zm6.7,0c0-1.95-.9-3.1-2.41-3.1s-2.4,1.16-2.4,3.1.9,3.11,2.4,3.11,2.41-1.15,2.41-3.11Z"
      fill="#fff"
    />
    <path
      d="M97.68,23.2h1.77v1.54h0a2.16,2.16,0,0,1,2.18-1.64,2.7,2.7,0,0,1,.63.07v1.74a2.6,2.6,0,0,0-.83-.11,1.66,1.66,0,0,0-.79.14,1.77,1.77,0,0,0-.66.45,2.11,2.11,0,0,0-.41.69,2,2,0,0,0-.08.8v5.37H97.68Z"
      fill="#fff"
    />
    <path
      d="M110.88,29.59c-.25,1.64-1.85,2.77-3.9,2.77-2.63,0-4.27-1.76-4.27-4.59s1.65-4.69,4.19-4.69S111,24.8,111,27.55v.64h-6.39v.11a2.34,2.34,0,0,0,.64,1.83,2.44,2.44,0,0,0,.82.56,2.19,2.19,0,0,0,1,.17,2,2,0,0,0,1.25-.29,2.06,2.06,0,0,0,.84-1Zm-6.28-2.7h4.52A2,2,0,0,0,109,26a2.15,2.15,0,0,0-.47-.76,2.36,2.36,0,0,0-.74-.5,2.22,2.22,0,0,0-.88-.16,2.42,2.42,0,0,0-.88.17,2.35,2.35,0,0,0-.75.5,2.4,2.4,0,0,0-.5.75,2.38,2.38,0,0,0-.17.88Z"
      fill="#fff"
    />
    <path
      d="M39.32,10.48a2.7,2.7,0,0,1,2.11.82,2.6,2.6,0,0,1,.59,1,2.63,2.63,0,0,1,.11,1.15c0,1.9-1,3-2.81,3H37.17v-6Zm-1.23,5.13h1.13a1.92,1.92,0,0,0,.82-.14,1.74,1.74,0,0,0,.67-.47,1.78,1.78,0,0,0,.41-.72,1.74,1.74,0,0,0,.07-.82,1.91,1.91,0,0,0-.07-.82,1.94,1.94,0,0,0-.41-.71,2,2,0,0,0-.68-.47,1.87,1.87,0,0,0-.81-.13H38.09Z"
      fill="#fff"
    />
    <path
      d="M43.18,14.2a2,2,0,0,1,.1-.88,2.08,2.08,0,0,1,.44-.76,2.18,2.18,0,0,1,.72-.52,2.14,2.14,0,0,1,1.72,0,2.08,2.08,0,0,1,.72.52,2.25,2.25,0,0,1,.45.76,2.17,2.17,0,0,1,.09.88,2.14,2.14,0,0,1-.09.87,2.2,2.2,0,0,1-.45.77,2.11,2.11,0,0,1-.71.51,2,2,0,0,1-.87.19,2,2,0,0,1-.86-.19,2.15,2.15,0,0,1-.72-.51,2,2,0,0,1-.44-.77A2,2,0,0,1,43.18,14.2Zm3.33,0c0-1-.44-1.55-1.21-1.55s-1.2.57-1.2,1.55.43,1.55,1.2,1.55,1.21-.57,1.21-1.55Z"
      fill="#fff"
    />
    <path
      d="M53.07,16.45h-.92l-.93-3.32h-.07l-.93,3.32h-.91L48.07,12H49l.8,3.43h.07L50.77,12h.85l.92,3.43h.08l.8-3.43h.89Z"
      fill="#fff"
    />
    <path
      d="M55.35,12h.86v.71h.06a1.41,1.41,0,0,1,.55-.61,1.32,1.32,0,0,1,.8-.19,1.39,1.39,0,0,1,.64.1,1.51,1.51,0,0,1,.54.36,1.4,1.4,0,0,1,.32.57,1.3,1.3,0,0,1,.05.65v2.91h-.88V13.76c0-.72-.32-1.08-1-1.08a.93.93,0,0,0-.43.07,1.15,1.15,0,0,0-.37.25,1,1,0,0,0-.22.38.94.94,0,0,0,0,.44v2.63h-.89Z"
      fill="#fff"
    />
    <path d="M60.59,10.19h.89v6.26h-.89Z" fill="#fff" />
    <path
      d="M62.71,14.2a2.36,2.36,0,0,1,.1-.88,2.25,2.25,0,0,1,.45-.76A2.08,2.08,0,0,1,64,12a2.14,2.14,0,0,1,1.72,0,2.18,2.18,0,0,1,.72.52,2.08,2.08,0,0,1,.44.76,2,2,0,0,1,.1.88,2,2,0,0,1-.1.87,2,2,0,0,1-.44.77,2.15,2.15,0,0,1-.72.51,2,2,0,0,1-.86.19,2,2,0,0,1-.87-.19,2.11,2.11,0,0,1-.71-.51,2.2,2.2,0,0,1-.45-.77A2.34,2.34,0,0,1,62.71,14.2Zm3.34,0c0-1-.44-1.55-1.21-1.55s-1.21.57-1.21,1.55.44,1.55,1.21,1.55,1.21-.57,1.21-1.55Z"
      fill="#fff"
    />
    <path
      d="M67.9,15.18c0-.81.6-1.28,1.67-1.35l1.22-.07v-.39c0-.47-.31-.74-.92-.74s-.84.18-.94.5h-.86c.09-.77.82-1.27,1.84-1.27s1.77.56,1.77,1.51v3.08h-.86v-.63h-.07a1.48,1.48,0,0,1-.58.53,1.38,1.38,0,0,1-.77.17,1.51,1.51,0,0,1-.56-.05,1.36,1.36,0,0,1-.49-.29,1.22,1.22,0,0,1-.33-.45A1.33,1.33,0,0,1,67.9,15.18Zm2.89-.39v-.37l-1.1.07c-.62,0-.9.25-.9.65s.35.64.84.64a1,1,0,0,0,.42,0,1.11,1.11,0,0,0,.37-.2.93.93,0,0,0,.26-.33A1.19,1.19,0,0,0,70.79,14.79Z"
      fill="#fff"
    />
    <path
      d="M72.85,14.2c0-1.43.73-2.33,1.86-2.33a1.5,1.5,0,0,1,.81.2,1.56,1.56,0,0,1,.58.59h.06V10.19h.89v6.26H76.2v-.71h-.07a1.55,1.55,0,0,1-.6.59,1.46,1.46,0,0,1-.82.19C73.57,16.52,72.85,15.62,72.85,14.2Zm.91,0c0,1,.45,1.53,1.21,1.53s1.21-.59,1.21-1.53-.47-1.53-1.21-1.53-1.21.58-1.21,1.53Z"
      fill="#fff"
    />
    <path
      d="M80.73,14.2a2,2,0,0,1,.1-.88,2.08,2.08,0,0,1,.44-.76A2.18,2.18,0,0,1,82,12a2.14,2.14,0,0,1,1.72,0,2.12,2.12,0,0,1,1.16,1.28,2,2,0,0,1,.1.88,2.14,2.14,0,0,1-.09.87,2.2,2.2,0,0,1-.45.77,2.05,2.05,0,0,1-.72.51,2,2,0,0,1-1.72,0,2,2,0,0,1-1.16-1.28A2,2,0,0,1,80.73,14.2Zm3.33,0c0-1-.44-1.55-1.21-1.55s-1.2.57-1.2,1.55.43,1.55,1.2,1.55S84.06,15.18,84.06,14.2Z"
      fill="#fff"
    />
    <path
      d="M86.17,12H87v.71h.07a1.36,1.36,0,0,1,1.34-.8,1.47,1.47,0,0,1,1.18.46A1.47,1.47,0,0,1,90,13.54v2.91H89.1V13.76c0-.72-.31-1.08-1-1.08a.89.89,0,0,0-.43.07,1.05,1.05,0,0,0-.37.25,1.14,1.14,0,0,0-.23.38,1.08,1.08,0,0,0,0,.44v2.63h-.89Z"
      fill="#fff"
    />
    <path
      d="M95,10.83V12h1v.75H95V15c0,.47.2.68.64.68l.34,0v.74a3.87,3.87,0,0,1-.49.05c-1,0-1.38-.35-1.38-1.22V12.72h-.71V12h.71V10.83Z"
      fill="#fff"
    />
    <path
      d="M97.2,10.19h.88v2.48h.07a1.4,1.4,0,0,1,1.38-.81,1.53,1.53,0,0,1,.63.11,1.45,1.45,0,0,1,.54.37,1.49,1.49,0,0,1,.32.56,1.4,1.4,0,0,1,.06.64v2.91h-.89V13.76c0-.72-.34-1.08-1-1.08a1.17,1.17,0,0,0-.45.06,1,1,0,0,0-.38.25,1,1,0,0,0-.24.38,1.14,1.14,0,0,0-.06.45v2.63H97.2Z"
      fill="#fff"
    />
    <path
      d="M106.26,15.23a1.8,1.8,0,0,1-.74,1,1.86,1.86,0,0,1-1.21.3,2,2,0,0,1-.87-.17,2.11,2.11,0,0,1-.71-.51,2,2,0,0,1-.43-.77,2,2,0,0,1-.07-.88,2,2,0,0,1,.07-.88,2.18,2.18,0,0,1,.43-.77,2.08,2.08,0,0,1,1.57-.7c1.26,0,2,.86,2,2.27v.31h-3.18v.05a1.35,1.35,0,0,0,.06.49,1.14,1.14,0,0,0,.26.42,1.12,1.12,0,0,0,.4.28,1.15,1.15,0,0,0,.48.1,1,1,0,0,0,.62-.11,1.06,1.06,0,0,0,.45-.44Zm-3.13-1.45h2.28a1.09,1.09,0,0,0-.06-.44,1.07,1.07,0,0,0-.61-.64,1,1,0,0,0-.44-.08,1.16,1.16,0,0,0-.45.08,1.39,1.39,0,0,0-.38.25,1.2,1.2,0,0,0-.34.83Z"
      fill="#fff"
    />
  </svg>
)
