import { createUseStyles } from 'react-jss'

import { Z_INDEX, COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    zIndex: Z_INDEX.CONTENT,
  },
  content: {
    zIndex: Z_INDEX.CONTENT,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    maxWidth: 1920,
  },
  logo: {
    margin: [80, 0, 40, 0],
    height: 150,
    width: '50%',
    [MQ.TABLET]: {
      height: 150,
      width: '20%',
      margin: [120, 0, 30, 0],
    },
    [MQ.DESKTOP]: {
      height: 224,
      margin: [170, 0, 45, 0],
    },
    [MQ.DESKTOP_LARGE]: {
      margin: [230, 0, 60, 0],
    },
  },
  subTitle: {
    textAlign: 'center',
    color: COLOR.BEIGE,
    padding: [0, 0, 30, 0],
    [MQ.TABLET]: {
      padding: [0, 0, 40, 0],
    },
    [MQ.DESKTOP]: {
      padding: [0, 0, 40, 0],
    },
    [MQ.DESKTOP_LARGE]: {
      padding: [0, 0, 50, 0],
    },
  },
  body: {
    color: COLOR.WHITE,
    paddingBottom: 60,
    margin: 'auto',
    width: '80%',
    [MQ.TABLET]: {
      paddingBottom: 80,
      maxWidth: 780,
    },
    [MQ.DESKTOP]: {
      padding: [0, 0, 100, 0],
      width: '50%',
      maxWidth: 960,
    },
    [MQ.DESKTOP_LARGE]: {
      padding: [0, 0, 130, 0],
    },
  },
  linkContainer: {
    textAlign: 'center',
    paddingBottom: 155,
    position: 'relative',
    [MQ.TABLET]: {
      paddingBottom: 110,
    },
    [MQ.DESKTOP]: {
      paddingBottom: 200,
    },
    [MQ.DESKTOP_LARGE]: {
      paddingBottom: 225,
    },
  },
  star: {
    position: 'absolute',
    width: 100,
    height: 100,
    bottom: -45,
    right: 20,

    [MQ.TABLET]: {
      width: 137,
      height: 137,
      right: 'auto',
      left: 51,
      bottom: -50,
    },
    [MQ.TABLET_LARGE]: {
      width: 155,
      height: 155,
      bottom: -60,
      left: 100,
    },
    [MQ.DESKTOP]: {
      width: 170,
      height: 175,
      bottom: -70,
      left: 115,
    },
  },
  squiggle: {
    position: 'absolute',
    width: 152,
    height: 100,
    top: -45,
    left: -15,

    [MQ.TABLET]: {
      width: 190,
      height: 123,
      top: -40,
      left: 'auto',
      right: -36,
    },
    [MQ.TABLET_LARGE]: {
      width: 260,
      height: 170,
      top: -50,
      right: -50,
    },
    [MQ.DESKTOP]: {
      width: 345,
      height: 225,
      top: -90,
      right: -70,
    },
  },
  arrow: {
    position: 'absolute',
    transform: 'scaleX(-1) rotate(-20deg)',
    display: 'none',
    [MQ.DESKTOP]: {
      top: -40,
      display: 'block',
      width: 100,
      height: 82,
      left: -145,
    },
  },
})
