import { createUseStyles } from 'react-jss'

import { MQ, Z_INDEX, COLOR } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1980,
    padding: [113, 0, 40],
    contentVisibility: 'auto',

    [MQ.TABLET]: {
      padding: [184, 0, 165],
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    [MQ.TABLET_LARGE]: {
      padding: [70, 0, 125],
    },
    [MQ.DESKTOP]: {
      padding: [240, 0, 294],
    },
    [MQ.DESKTOP_LARGE]: {
      padding: [400, 0, 350],
      margin: 'auto',
    },
  },
  textBlock: {
    margin: [0, '8%', 55, '10%'],
    [MQ.TABLET]: {
      margin: [0, '7.6%', 0, 0],
      width: '38%',
    },
    [MQ.DESKTOP]: {
      width: '23.5%',
    },
  },
  title: {
    marginBottom: 42,
    [MQ.TABLET_LARGE]: {
      marginBottom: 30,
    },
    [MQ.DESKTOP]: {
      marginBottom: 40,
    },
  },
  body: {
    marginBottom: 42,
    [MQ.TABLET]: {
      marginBottom: 40,
    },
    [MQ.DESKTOP]: {
      marginBottom: 70,
      width: '75%',
    },
  },
  imageWrapper: {
    width: '100%',
    alignSelf: 'flex-end',
    margin: [0, 0, 96, 0],
    position: 'relative',
    [MQ.TABLET]: {
      width: '50%',
      minHeight: 386,
      margin: [0],
      alignSelf: 'unset',
    },
    [MQ.DESKTOP]: {
      width: '61.5%',
    },
  },
  image: {
    [MQ.TABLET]: {
      minHeight: 386,
    },
  },
  shortSquiggle: {
    position: 'absolute',
    width: 66,
    height: 97,
    top: -47,
    right: 31,
    zIndex: Z_INDEX.CONTENT,

    [MQ.TABLET]: {
      width: 112,
      height: 160,
      top: -110,
    },
    [MQ.TABLET_LARGE]: {
      width: 140,
      height: 200,
      top: -95,
    },
    [MQ.DESKTOP]: {
      width: 170,
      height: 210,
      top: -100,
      right: 170,
    },
    [MQ.DESKTOP_LARGE]: {
      width: 190,
      height: 271,
      top: -130,
    },
  },
  halfCircle: {
    position: 'absolute',
    width: 80,
    height: 60,
    bottom: -30,
    left: 64,
    zIndex: Z_INDEX.CONTENT,

    [MQ.TABLET]: {
      width: 112,
      height: 160,
      bottom: -100,
    },
    [MQ.TABLET_LARGE]: {
      width: 140,
      height: 200,
      bottom: -95,
    },
    [MQ.DESKTOP]: {
      width: 170,
      height: 150,
      bottom: -70,
    },
    [MQ.DESKTOP_LARGE]: {
      width: 186,
      height: 163,
      bottom: -90,
    },
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: 342,
  },
  storeLinks: {
    width: '100%',
    margin: [30, 0, 0],
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 261,
    [MQ.TABLET_LARGE]: {
      margin: [30, 0, 0],
    },
  },
  store: {
    width: '43%',
    height: 45,
    padding: [2.5, 5],
  },
  noLinkStore: {
    backgroundColor: COLOR.BLUE,
    borderRadius: 10,
  },
})
