import * as React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import { isNilOrEmpty } from 'ramdasauce'

import { useStyles } from './styles'
import { Text, Button, Svg, AccessibleText } from '@atoms'
import { DownloadButtons } from '@molecules'
import { COLOR } from '@constants'
import { getOperatingSystemLink } from '@helpers'

export const Storytime = ({ image }) => {
  const classes = useStyles()
  const imgSrc = getImage(image)

  const downloadButtonLink = getOperatingSystemLink()

  const storeClasses = cn({
    [classes.store]: true,
    [classes.noLinkStore]: isNilOrEmpty(downloadButtonLink),
  })

  return (
    <div className={classes.wrapper}>
      <div className={classes.imageWrapper}>
        <GatsbyImage image={imgSrc} alt="image" className={classes.image} />
        <div className={classes.shortSquiggle}>
          <Svg name="short-squiggle" />
        </div>
        <div className={classes.halfCircle}>
          <Svg name="half-circle" />
        </div>
      </div>
      <div className={classes.textBlock}>
        <div className={classes.title}>
          <Text size="header1" tag="h2">
            Explore them all for free
          </Text>
        </div>
        <div className={classes.body}>
          <Text size="body4" tag="p">
            Discover a world of adventure and explore all five Novel Effect
            original titles made in collaboration with Annie’s&#8482; Mac &amp;
            Cheese.
          </Text>
        </div>
        <DownloadButtons
          text="DOWNLOAD THE FREE APP"
          page="section3"
          buttonColor={COLOR.BLUE}
          textColor={COLOR.BLACK}
        />
      </div>
    </div>
  )
}

Storytime.propTypes = {
  image: PropTypes.object,
}

Storytime.defaultProps = {
  image: null,
}
