import * as React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { useStyles } from './styles'
import { Text, Button } from '@atoms'
import { COLOR, LINKS } from '@constants'

export const FollowUs = ({ images }) => {
  const classes = useStyles()

  const renderImages = (images) =>
    images.map((item, i) => {
      const image = getImage(item)

      return (
        <div className={classes.image} key={i}>
          <GatsbyImage
            image={image}
            alt="image"
            formats={['AUTO', 'WEBP', 'AVIF']}
            placeholder="none"
          />
        </div>
      )
    })

  return (
    <div className={classes.wrapper}>
      <div className={classes.textBlock}>
        <Text tag="h2" size="header1">
          Follow Us
        </Text>
        <div className={classes.body}>
          <Text tag="p" size="body4">
            Follow along on Instagram and Facebook to see all the fun ways
            Annie’s&#8482; and Novel Effect are transforming meal prep together.
          </Text>
        </div>
        <div className={classes.buttons}>
          <Button
            text="@annieshomegrown"
            link={LINKS.ANNIES_INSTAGRAM}
            color={COLOR.PURPLE}
            styles="wide"
            textStyle="button1"
          />
          <Button
            text="@novel_effect"
            link={LINKS.INSTAGRAM}
            color={COLOR.BLUE}
            styles="wide"
            textStyle="button1"
          />
        </div>
      </div>
      <div className={classes.imageContainer}>{renderImages(images)}</div>
    </div>
  )
}

FollowUs.propTypes = {
  images: PropTypes.array,
}

FollowUs.defaultProps = {
  images: [],
}
