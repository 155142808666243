import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  footerWrapper: {
    backgroundColor: COLOR.LIGHT_GREY,
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: 'auto',
    width: '100%',
    alignItems: 'center',
    fontFamily: `'Lota'`,
    [MQ.TABLET]: {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    [MQ.TABLET_LARGE]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  social: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    order: 3,
    paddingBottom: 50,
    [MQ.TABLET]: {
      width: '45%',
    },
    [MQ.TABLET_LARGE]: {
      width: '30%',
      order: 1,
    },
  },
  instagram: {
    height: 25,
    width: 25,
    margin: 19,
  },
  facebook: {
    height: 25,
    width: 25,
    margin: 19,
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    order: 1,
    padding: [30, 15, 0],
    maxWidth: 330,
    [MQ.TABLET]: {
      width: '55%',
      maxWidth: 450,
    },
    [MQ.TABLET_LARGE]: {
      width: '30%',
      order: 2,
    },
  },

  annies: {
    height: 107,
    width: '33%',
  },
  novelEffect: {
    height: 65,
    width: '33%',
  },
  cross: {
    width: 10,
    height: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [MQ.TABLET]: {
      width: 24,
      height: 24,
    },
    '&:before': {
      position: 'absolute',
      height: 13,
      width: 2,
      backgroundColor: COLOR.DARK_GREY,
      transform: 'rotate(45deg)',
      content: '""',
      borderRadius: 5,
      [MQ.TABLET]: {
        height: 24,
      },
    },
    '&:after': {
      position: 'absolute',
      height: 13,
      width: 2,
      backgroundColor: COLOR.DARK_GREY,
      transform: 'rotate(-45deg)',
      content: '""',
      borderRadius: 5,
      [MQ.TABLET]: {
        height: 24,
      },
    },
  },
  linkContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    order: 2,
    padding: [30, 0, 30],
    fontWeight: 700,

    [MQ.TABLET]: {
      width: '45%',
    },
    [MQ.TABLET_LARGE]: {
      width: '30%',
    },
    [MQ.DESKTOP]: {
      padding: [110, 0],
    },
    [MQ.DESKTOP_LARGE]: {
      padding: [165, 0, 165],
    },
  },
  link: {
    fontSize: 10,
    color: COLOR.DARK_GREY,
    textDecoration: 'none',
    margin: 10,
  },
  copyright: {
    textAlign: 'center',
    fontSize: 15,
    marginBottom: 20,
    fontWeight: 400,

    [MQ.TABLET_LARGE]: {
      margin: [30, 0, 30, 0],
    },
    [MQ.DESKTOP]: {
      marginTop: 0,
    },
    [MQ.DESKTOP_LARGE]: {
      marginBottom: 73,
    },
  },
  footerLink: {
    color: COLOR.BLACK,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})
