import * as React from 'react'
import PropTypes from 'prop-types'

import { SVGS } from './js/constants'
import { useStyles } from './styles'

// get data object connected to the "name" prop of the component
// object: {RATIO (percentage of h:w), SVG (svg react component)}
const getSvg = (name) => {
  const key = name.replace(/-/g, '_').toUpperCase()
  return SVGS[key]
}

export const Svg = ({ svgFill, pathFill, rectFill, name }) => {
  const classes = useStyles({
    svgFill,
    pathFill,
    rectFill,
  })
  const SVG_DATA = getSvg(name)

  return (
    <>{SVG_DATA && <div className={classes.element}>{SVG_DATA.SVG}</div>}</>
  )
}

Svg.propTypes = {
  pathFill: PropTypes.string,
  svgFill: PropTypes.string,
  rectFill: PropTypes.string,
}

Svg.defaultProps = {
  svgFill: 'none',
  rectFill: 'none',
}
