import * as React from 'react'

export const peace = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="76.34"
    height="80.94"
    viewBox="0 0 76.34 80.94"
  >
    <path
      d="M40.43,76.78A33.49,33.49,0,1,0,6.88,43.29,33.52,33.52,0,0,0,40.43,76.78Z"
      fill="#f69797"
    />
    <path
      d="M60.41,10.12a35.59,35.59,0,0,0-49.79,6.4,35.06,35.06,0,0,0-7,26.56v.21A35.45,35.45,0,0,0,17,66.22a35.64,35.64,0,0,0,49.89-6.29A35.69,35.69,0,0,0,60.41,10.12ZM13.72,18.87A31.6,31.6,0,0,1,50,8.84L39.79,34.65,7.2,40.52A31.76,31.76,0,0,1,13.72,18.87Zm-6,25.49,30.34-5.44L26.86,67.4a31,31,0,0,1-7.48-4.27A31.55,31.55,0,0,1,7.74,44.36ZM42.67,69.42a31,31,0,0,1-12.18-.85l11.33-28.8L54.1,65.69A28.14,28.14,0,0,1,42.67,69.42Zm21-11.94a31.26,31.26,0,0,1-6.3,6.08L44.06,35.29a.1.1,0,0,0-.11-.1c0-.11-.1-.11-.1-.22v-.1l9.72-24.64a51.24,51.24,0,0,1,4.49,2.88A31.68,31.68,0,0,1,63.72,57.48Z"
      fill="#e23f40"
    />
  </svg>
)
