import * as files from '../svgs'

// Calculate SVGS.RATIO //
// Percentage of svg h:w
// height: Int, width: Int
// You can find the h & w in the svg `viewbox` attr
const getRatio = (height, width) => {
  return `${(height / width) * 100}%`
}

export const SVGS = {
  // LOGO: {
  //   RATIO: getRatio(1, 1),
  //   SVG: files.logo(),
  // },
  INSTAGRAM: {
    RATIO: getRatio(1, 1), // '100%'
    SVG: files.instagram(),
  },
  ANNIES: {
    RATIO: getRatio(187, 145),
    SVG: files.annies(),
  },
  NOVEL_EFFECT: {
    RATIO: getRatio(184, 73),
    SVG: files.novelEffect(),
  },
  FACEBOOK: {
    RATIO: getRatio(1, 1), // '100%'
    SVG: files.facebook(),
  },
  STAR: {
    RATIO: getRatio(1, 1),
    SVG: files.star(),
  },
  SQUIGGLE: {
    RATIO: getRatio(1, 1),
    SVG: files.squiggle(),
  },
  SHORT_SQUIGGLE: {
    RATIO: getRatio(1, 1),
    SVG: files.shortSquiggle(),
  },
  SUNRISE: {
    RATIO: getRatio(1, 1),
    SVG: files.sunrise(),
  },
  THREE: {
    RATIO: getRatio(1, 1),
    SVG: files.three(),
  },
  ARROW: {
    RATIO: getRatio(1, 1),
    SVG: files.arrow(),
  },
  HALF_CIRCLE: {
    RATIO: getRatio(1, 1),
    SVG: files.halfCircle(),
  },
  ANDROID_DOWNLOAD: {
    RATIO: getRatio(1, 1),
    SVG: files.androidDownload(),
  },
  APPLE_DOWNLOAD: {
    RATIO: getRatio(1, 1),
    SVG: files.appleDownload(),
  },
  PLAY_BUTTON: {
    RATIO: getRatio(1, 1),
    SVG: files.playButton(),
  },
  NOODLE: {
    RATIO: getRatio(1, 1),
    SVG: files.noodle(),
  },
  PEACE: {
    RATIO: getRatio(1, 1),
    SVG: files.peace(),
  },
}
