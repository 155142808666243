import { createUseStyles } from 'react-jss'

import { Z_INDEX, COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    contentVisibility: 'auto',

    [MQ.DESKTOP]: {
      alignItems: 'center',
    },
  },
  content: {
    zIndex: Z_INDEX.CONTENT,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: COLOR.WHITE,
    maxWidth: 1920,
    margin: 'auto',
    width: '80%',
    [MQ.TABLET_LARGE]: {
      justifyContent: 'center',
      width: 'unset',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: [0, '5.5%'],
      height: (props) => `calc(${props.columnHeight}px + 150px)`,
      minHeight: 975,
    },
    [MQ.DESKTOP]: {
      minHeight: 1000,
      height: (props) => `calc(${props.columnHeight}px + 130px)`,
    },
    [MQ.DESKTOP_LARGE]: {
      height: (props) => `calc(${props.columnHeight}px + 220px)`,
    },
  },
  firstSection: {
    order: 2,
    maxWidth: 650,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [MQ.TABLET_LARGE]: {
      alignSelf: 'flex-start',
      maxWidth: 'none',
      width: '40%',
    },
    [MQ.DESKTOP]: {
      width: '51%',
    },
  },
  title: {
    maxWidth: 726,
    marginTop: 75,
    textAlign: 'center',
    [MQ.TABLET_LARGE]: {
      textAlign: 'left',
      alignSelf: 'flex-start',
      marginTop: 30,
    },
    [MQ.DESKTOP]: {
      marginTop: 75,
    },
  },
  subTitle: {
    marginTop: 30,
    textAlign: 'center',
    [MQ.TABLET]: {
      width: '70%',
    },
    [MQ.TABLET_LARGE]: {
      marginTop: 50,
      textAlign: 'left',
      width: '100%',
      alignSelf: 'flex-start',
    },
    [MQ.DESKTOP]: {
      width: '80%',
    },
    [MQ.DESKTOP_LARGE]: {
      marginTop: 55,
    },
  },
  body: {
    marginTop: 30,
    [MQ.TABLET_LARGE]: {
      marginTop: 50,
      alignSelf: 'flex-start',
    },
    [MQ.DESKTOP]: {
      width: '94%',
    },
    [MQ.DESKTOP_LARGE]: {
      marginTop: 65,
    },
  },

  image: {
    order: 1,
    width: '86%',
    border: '15px solid white',
    [MQ.TABLET]: {
      width: '44%',
    },
    [MQ.TABLET_LARGE]: {
      order: 3,
      width: '43%',
      marginBottom: 60,
    },
    [MQ.DESKTOP]: {
      marginBottom: 70,
      width: '36%',
    },
    [MQ.DESKTOP_LARGE]: {
      marginBottom: 80,
    },
  },

  linkContainer: {
    order: 3,
    margin: [40, 0, 60, 0],
    [MQ.TABLET]: {
      margin: [50, 0, 60, 0],
    },
    [MQ.TABLET_LARGE]: {
      margin: [30, 0, 0],
      alignSelf: 'flex-start',
    },
    [MQ.DESKTOP]: {
      margin: [80, 0, 0],
    },
  },
  secondImage: {
    width: '86%',
    order: 4,
    border: '15px solid white',
    [MQ.TABLET]: {
      width: '59.9%',
    },
    [MQ.TABLET_LARGE]: {
      width: '58%',
    },
    [MQ.DESKTOP]: {
      width: '54%',
    },
  },
})
