import * as React from 'react'
import { isNilOrEmpty } from 'ramdasauce'
import { Text } from '@atoms'
import { useStyles } from './styles'

export const AccessibleText = ({ children }) => {
  const classes = useStyles()

  return (
    !isNilOrEmpty(children) && (
      <Text tag={'span'} size={'body1'} className={[classes.text]}>
        {children}
      </Text>
    )
  )
}
