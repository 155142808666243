import * as React from 'react'
// import PropTypes from 'prop-types'

import { useStyles } from './styles'

export const Main = ({ children }) => {
  const classes = useStyles()

  return (
    <main className={classes.main} role="main">
      {children}
    </main>
  )
}

// Main.propTypes = {
//   children: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node,
//   ]),
// }

// Main.defaultProps = {
//   children: undefined,
// }
