import * as React from 'react'

export const sunrise = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="229.15"
    height="194.39"
    viewBox="0 0 229.15 194.39"
  >
    <path
      d="M208.63,144l-60.3-39.18,25.88,67.27a12.18,12.18,0,1,1-22.71,8.83l-26.08-67.4-18.65,69.6a12.21,12.21,0,1,1-23.59-6.28l18.64-69.6-56,45.32a12.2,12.2,0,1,1-15.32-19l56-45.33-72,3.69a12.18,12.18,0,0,1-1.17-24.34l72-3.69L24.63,24.44A12.3,12.3,0,0,1,38,3.81l91.9,59.71L222,123.35a12.27,12.27,0,0,1,3.57,16.85A12,12,0,0,1,208.63,144Z"
      fill="#4bb6c1"
    />
  </svg>
)
