import * as React from 'react'

export const androidDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="132.25"
    height="46.55"
    viewBox="0 0 132.25 46.55"
  >
    <defs>
      <clipPath id="a">
        <rect x="1.3" y="1.54" width="129.65" height="43.47" fill="none" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        d="M124.5,45H7.75A6.44,6.44,0,0,1,1.3,38.56V8A6.44,6.44,0,0,1,7.75,1.54H124.58A6.44,6.44,0,0,1,131,8V38.56A6.56,6.56,0,0,1,124.5,45ZM7.75,3.06A4.92,4.92,0,0,0,2.82,8V38.56a4.92,4.92,0,0,0,4.93,4.93H124.58a4.92,4.92,0,0,0,4.93-4.93V8a4.92,4.92,0,0,0-4.93-4.93Z"
        fill="#fff"
      />
      <path
        d="M44.39,14.21h-.83a5.23,5.23,0,0,0-.23-1H42.12a5.61,5.61,0,0,0-.23,1h-.84c0-.08.23-.61.61-1.75a8.36,8.36,0,0,0,.61-1.74h1c0,.08.23.68.61,1.74S44.39,14.13,44.39,14.21Zm-1.21-1.52a13.17,13.17,0,0,1-.46-1.51h-.07a13.17,13.17,0,0,1-.46,1.51Z"
        fill="#fff"
      />
      <path
        d="M47.88,14.21H47.2a4.72,4.72,0,0,0-.76-1.14,6.32,6.32,0,0,1-.91-1.52h-.08a5.15,5.15,0,0,1,.08,1.07v1.59h-.68c0-.53.07-1.14.07-1.82a11.67,11.67,0,0,0-.07-1.67h.83a6.13,6.13,0,0,0,.68,1.14,8.53,8.53,0,0,1,.84,1.44h.07a5.82,5.82,0,0,1-.07-1.06V10.72h.68v1.67A7.88,7.88,0,0,0,47.88,14.21Z"
        fill="#fff"
      />
      <path
        d="M51.75,12.31a2.15,2.15,0,0,1-.45,1.37,1.68,1.68,0,0,1-1.37.61H48.87V10.8H50a2,2,0,0,1,1.44.45A2,2,0,0,1,51.75,12.31Zm-.76.15a1,1,0,0,0-.3-.83,1.25,1.25,0,0,0-.91-.38h-.31v2.43h.38a1,1,0,0,0,.84-.38A1.13,1.13,0,0,0,51,12.46Z"
        fill="#fff"
      />
      <path
        d="M55.39,14.21h-.76c-.22-.61-.38-1.06-.45-1.21a.58.58,0,0,0-.53-.23h-.31v1.36h-.75V10.64H53.8c.83,0,1.21.31,1.21.84a.89.89,0,0,1-.68.91v.07a1.42,1.42,0,0,1,.46.46C54.94,13.15,55.09,13.6,55.39,14.21Zm-1.06-2.5c0-.38-.23-.53-.68-.53h-.46v1.06h.23C54,12.31,54.33,12.16,54.33,11.71Z"
        fill="#fff"
      />
      <path
        d="M59.19,12.39a1.81,1.81,0,0,1-.54,1.36,1.5,1.5,0,0,1-1.21.54,1.63,1.63,0,0,1-1.21-.54,1.74,1.74,0,0,1-.46-1.29,1.81,1.81,0,0,1,.53-1.36,1.52,1.52,0,0,1,1.22-.53,1.61,1.61,0,0,1,1.21.53A1.94,1.94,0,0,1,59.19,12.39Zm-.69.07a1.21,1.21,0,0,0-.3-.91.82.82,0,0,0-1.37,0,1.67,1.67,0,0,0-.3.91,1.27,1.27,0,0,0,.3.92.82.82,0,0,0,1.37,0A1.68,1.68,0,0,0,58.5,12.46Z"
        fill="#fff"
      />
      <path d="M60.7,14.21h-.76V10.72h.76v3.49Z" fill="#fff" />
      <path
        d="M64.65,12.31a2.1,2.1,0,0,1-.46,1.37,1.66,1.66,0,0,1-1.36.61H61.76V10.8H62.9a1.93,1.93,0,0,1,1.44.45A1.91,1.91,0,0,1,64.65,12.31Zm-.76.15a1.07,1.07,0,0,0-.3-.83,1.27,1.27,0,0,0-.91-.38h-.31v2.43h.38a1,1,0,0,0,.84-.38A1.18,1.18,0,0,0,63.89,12.46Z"
        fill="#fff"
      />
      <path
        d="M69.81,14.21H69a4.5,4.5,0,0,0-.23-1H67.53a6.84,6.84,0,0,0-.23,1h-.83c0-.08.23-.61.61-1.75a8.65,8.65,0,0,0,.6-1.74h1c0,.08.23.68.61,1.74A13.51,13.51,0,0,1,69.81,14.21Zm-1.22-1.52a14.27,14.27,0,0,1-.45-1.51h-.08a14.27,14.27,0,0,1-.45,1.51Z"
        fill="#fff"
      />
      <path
        d="M72.92,11.63a1.14,1.14,0,0,1-.46,1,1.87,1.87,0,0,1-1.14.3h-.15v1.37h-.76V10.8H71.7a1.55,1.55,0,0,1,.84.22C72.84,11.1,72.92,11.33,72.92,11.63Zm-.69.15c0-.38-.22-.6-.75-.6a.72.72,0,0,0-.38.07v1.14h.15C71.93,12.39,72.23,12.16,72.23,11.78Z"
        fill="#fff"
      />
      <path
        d="M76.18,11.63a1.12,1.12,0,0,1-.46,1,1.86,1.86,0,0,1-1.13.3h-.16v1.37h-.75V10.8H75a1.54,1.54,0,0,1,.83.22A.61.61,0,0,1,76.18,11.63Zm-.68.15c0-.38-.23-.6-.76-.6a.74.74,0,0,0-.38.07v1.14h.15C75.19,12.39,75.5,12.16,75.5,11.78Z"
        fill="#fff"
      />
      <path
        d="M81.49,12.39A1.8,1.8,0,0,1,81,13.75a1.53,1.53,0,0,1-1.22.54,1.63,1.63,0,0,1-1.21-.54,1.78,1.78,0,0,1-.45-1.29,1.81,1.81,0,0,1,.53-1.36,1.5,1.5,0,0,1,1.21-.53A1.6,1.6,0,0,1,81,11.1,2.39,2.39,0,0,1,81.49,12.39Zm-.76.07a1.26,1.26,0,0,0-.3-.91.82.82,0,0,0-1.37,0,1.67,1.67,0,0,0-.3.91,1.22,1.22,0,0,0,.3.92.82.82,0,0,0,1.37,0A1.27,1.27,0,0,0,80.73,12.46Z"
        fill="#fff"
      />
      <path
        d="M85.13,14.21h-.68a4.72,4.72,0,0,0-.76-1.14,6,6,0,0,1-.91-1.52H82.7a5.15,5.15,0,0,1,.08,1.07v1.59H82.1c0-.53.07-1.14.07-1.82a11.67,11.67,0,0,0-.07-1.67h.83a6.13,6.13,0,0,0,.68,1.14,8.53,8.53,0,0,1,.84,1.44h.07a5.82,5.82,0,0,1-.07-1.06V10.72h.68v1.67A7.88,7.88,0,0,0,85.13,14.21Z"
        fill="#fff"
      />
      <path
        d="M53.34,25.44a5.89,5.89,0,0,1-1.51,4.17,6.08,6.08,0,0,1-4.56,1.82,6,6,0,0,1-4.55-1.9,6.1,6.1,0,0,1-1.89-4.62,6.12,6.12,0,0,1,1.89-4.63,6.52,6.52,0,0,1,4.55-1.9,6,6,0,0,1,2.58.53,6.49,6.49,0,0,1,2,1.37l-1.22,1.14A4.18,4.18,0,0,0,47.2,20a4.9,4.9,0,0,0-3.42,1.36,4.67,4.67,0,0,0-1.44,3.57,4.64,4.64,0,0,0,1.44,3.56,4.91,4.91,0,0,0,3.42,1.37,4.82,4.82,0,0,0,3.49-1.37A3.79,3.79,0,0,0,51.75,26H47.2V24.45h6.07A5.06,5.06,0,0,1,53.34,25.44Z"
        fill="#fff"
      />
      <path
        d="M55.24,23.69a4.31,4.31,0,0,1,3.11-1.29,4.07,4.07,0,0,1,3.11,1.29A4.47,4.47,0,0,1,62.68,27a4.58,4.58,0,0,1-1.22,3.26,4.31,4.31,0,0,1-3.11,1.29,4.07,4.07,0,0,1-3.11-1.29A4.5,4.5,0,0,1,54,27,4.38,4.38,0,0,1,55.24,23.69Zm1.21,5.47a2.79,2.79,0,0,0,2,.83,2.74,2.74,0,0,0,2-.83,3.05,3.05,0,0,0,.83-2.2,3,3,0,0,0-.83-2.2,2.71,2.71,0,0,0-2-.84,2.75,2.75,0,0,0-2,.84,3,3,0,0,0-.83,2.2A3.17,3.17,0,0,0,56.45,29.16Z"
        fill="#fff"
      />
      <path
        d="M64.57,23.69a4.33,4.33,0,0,1,3.11-1.29,4.07,4.07,0,0,1,3.11,1.29A4.47,4.47,0,0,1,72,27a4.58,4.58,0,0,1-1.22,3.26,4.3,4.3,0,0,1-3.11,1.29,4.07,4.07,0,0,1-3.11-1.29A4.81,4.81,0,0,1,63.36,27,4.1,4.1,0,0,1,64.57,23.69Zm1.14,5.47a2.75,2.75,0,0,0,3.94,0,3,3,0,0,0,.84-2.2,3,3,0,0,0-.84-2.2,2.73,2.73,0,0,0-3.94,0,3,3,0,0,0-.83,2.2A3.53,3.53,0,0,0,65.71,29.16Z"
        fill="#fff"
      />
      <path
        d="M76.79,35.22a4,4,0,0,1-2.51-.75,5.13,5.13,0,0,1-1.36-1.75l1.44-.61a2.13,2.13,0,0,0,.91,1.14,2.95,2.95,0,0,0,3.56-.3,2.79,2.79,0,0,0,.76-2.13V30h-.07a4.28,4.28,0,0,1-1.22,1.06,3.26,3.26,0,0,1-1.74.46,3.92,3.92,0,0,1-2.88-1.29A4.47,4.47,0,0,1,72.46,27a4.59,4.59,0,0,1,1.22-3.27,3.92,3.92,0,0,1,2.88-1.29,4.42,4.42,0,0,1,1.74.38,3.53,3.53,0,0,1,1.22,1.06h.07V22.63h1.52v8.12a4.36,4.36,0,0,1-1.29,3.34A4.21,4.21,0,0,1,76.79,35.22ZM76.94,30a2.57,2.57,0,0,0,1.89-.83,3,3,0,0,0,.76-2.28,3.28,3.28,0,0,0-.76-2.28,2.58,2.58,0,0,0-3.79,0A3.33,3.33,0,0,0,75,29,2.26,2.26,0,0,0,76.94,30Z"
        fill="#fff"
      />
      <path d="M84.3,31.2H82.7V18.69h1.6Z" fill="#fff" />
      <path
        d="M89.61,31.43a4,4,0,0,1-3.11-1.29,4.43,4.43,0,0,1-1.22-3.26,4.84,4.84,0,0,1,1.14-3.19,3.73,3.73,0,0,1,3-1.36,3.86,3.86,0,0,1,3,1.21,4.7,4.7,0,0,1,1.14,3.34v.3H86.88a3.1,3.1,0,0,0,.83,2.05,2.47,2.47,0,0,0,1.82.76,2.34,2.34,0,0,0,2.35-1.44l1.44.61A4.05,4.05,0,0,1,92,30.82,4.69,4.69,0,0,1,89.61,31.43Zm2.27-5.54a1.82,1.82,0,0,0-.68-1.36,2.23,2.23,0,0,0-1.82-.69,2.07,2.07,0,0,0-1.52.54A2.56,2.56,0,0,0,87,25.89Z"
        fill="#fff"
      />
      <path
        d="M99.85,31.2H98.26V18.69h4.24a4,4,0,0,1,2.73,1.06,3.66,3.66,0,0,1,0,5.31,4,4,0,0,1-2.73,1.06H99.85Zm0-6.6h2.65a2,2,0,0,0,1.6-.68,2.39,2.39,0,0,0,.6-1.52,2.37,2.37,0,0,0-.6-1.51,2,2,0,0,0-1.6-.69H99.85Z"
        fill="#fff"
      />
      <path d="M109,31.2h-1.6V18.69H109Z" fill="#fff" />
      <path
        d="M113.2,31.43a3.33,3.33,0,0,1-2.28-.76,2.61,2.61,0,0,1-.91-2.05,2.7,2.7,0,0,1,1.07-2.2,4.22,4.22,0,0,1,2.65-.75,4.06,4.06,0,0,1,2.28.53V26a2.12,2.12,0,0,0-.61-1.52,2.37,2.37,0,0,0-1.59-.53,2.56,2.56,0,0,0-1.29.3,1.81,1.81,0,0,0-.76.91l-1.44-.6a3.89,3.89,0,0,1,1.14-1.44,3.63,3.63,0,0,1,2.27-.69,3.93,3.93,0,0,1,2.73,1,3.47,3.47,0,0,1,1.06,2.73v5.16H116V30.07h-.08A3.07,3.07,0,0,1,113.2,31.43Zm.23-1.44a2.47,2.47,0,0,0,1.82-.76,2.25,2.25,0,0,0,.83-1.74,3.12,3.12,0,0,0-2-.53,2.41,2.41,0,0,0-1.74.53,1.56,1.56,0,0,0-.61,1.21,1.11,1.11,0,0,0,.53,1A3.22,3.22,0,0,0,113.43,30Z"
        fill="#fff"
      />
      <path
        d="M121.09,34.92h-1.67l2-4.32-3.49-8h1.75l2.5,6.15,2.51-6.15h1.74Z"
        fill="#fff"
      />
      <path
        d="M10.94,24.75V12.84a1.7,1.7,0,0,1,.45-1.29c.15-.15.23-.15.38,0,2.66,2.74,5.39,5.47,8,8.2,1.75,1.74,3.49,3.56,5.24,5.31.15.15.15.23,0,.45-4.33,4.1-8.58,8.35-12.82,12.6a.5.5,0,0,1-.69.07,1.65,1.65,0,0,1-.6-1.36V24.75Z"
        fill="#fff"
      />
      <path
        d="M12.45,11a1.29,1.29,0,0,1,.76.23c5.46,3,10.93,6.07,16.31,9.18.23.15.23.23.08.38C28.38,22,27.17,23.24,26,24.45c-.15.15-.23.23-.38,0l-13-13.27A.25.25,0,0,0,12.45,11Z"
        fill="#fff"
      />
      <path
        d="M13.67,38c3.94-4,7.89-8,11.83-12,.23-.22.38-.22.61,0,1,1.07,2.05,2.05,3,3.12.15.15.23.22-.07.37C24,32.34,18.9,35.07,13.82,37.88A.16.16,0,0,1,13.67,38Z"
        fill="#fff"
      />
      <path
        d="M36.27,24.83a1.17,1.17,0,0,1-.53,1c-.22.15-.53.3-.76.45-1.51.84-3.11,1.67-4.62,2.58-.23.15-.31.08-.53-.07-1.07-1.14-2.2-2.28-3.27-3.34-.15-.15-.15-.23,0-.38,1.29-1.29,2.58-2.58,3.87-3.79.15-.16.23-.16.46-.08,1.52.83,3,1.67,4.55,2.58A1.22,1.22,0,0,1,36.27,24.83Z"
        fill="#fff"
      />
    </g>
  </svg>
)
