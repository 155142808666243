import * as React from 'react'

import { Svg, Text, Button, WaveBackground, AccessibleText } from '@atoms'
import { COLOR, LINKS } from '@constants'
import { useStyles } from './styles'

export const Homegrown = () => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <WaveBackground color={COLOR.PURPLE} type="homegrown" />
      <div className={classes.content}>
        <div className={classes.logo}>
          <a href={LINKS.ANNIES} target="_blank" rel="noreferrer">
            <AccessibleText>Visit the Annie's Homegrown Website</AccessibleText>
            <Svg name="annies" />
          </a>
        </div>
        <div className={classes.subTitle}>
          <Text size="header2">Spoon Up Some Magic</Text>
        </div>
        <div className={classes.body}>
          <Text size="body1">
            Annie’s&#8482; is on a mission to cultivate a healthier, happier
            world by spreading goodness through nourishing foods, honest words,
            and conduct that is considerate and forever kind. Easily shop for
            select Annie's&#8482; Mac and Cheese with Built-in-play activities
            that work with Novel Effect.
          </Text>
        </div>
        <div className={classes.linkContainer}>
          <Button
            text={<>SHOP ANNIE'S&#8482;</>}
            link={LINKS.ANNIES_BASKET}
            color={COLOR.YELLOW}
            textStyle="button1"
          />
          <div className={classes.arrow}>
            <Svg name="arrow" pathFill={COLOR.WHITE} />
          </div>
        </div>
        <div className={classes.star}>
          <Svg name="star" />
        </div>
        <div className={classes.squiggle}>
          <Svg name="squiggle" />
        </div>
      </div>
    </div>
  )
}
